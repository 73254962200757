import { localLng } from 'constants/locale';
import { Paths } from 'constants/routes';
import { Configuration, RedirectRequest } from '@azure/msal-browser';
import wellKnownJsonTstAcc from './well-known-tst-acc.json';
import wellKnownJsonProd from './well-known-prod.json';
import safeWindow from 'utils/safeWindow';
import { safeEnv } from 'utils/safeEnv';

const getWellKnownJson = () => safeEnv('ENVIRONMENT')?.toLowerCase() === 'production' ? wellKnownJsonProd : wellKnownJsonTstAcc;

const getClientId = () => {
	switch (safeEnv('ENVIRONMENT')?.toLowerCase()) {
		case 'production':
			return '974df51c-9bb9-4ca6-9d37-58a9d5efae30';
		case 'acceptance':
			return '5e42f1ce-6e91-4708-b304-4606407092c1';
		case 'test':
			return 'cfd1dabd-ee42-40f9-b030-44147dded913';
		default:
			return '';
	}
};

const getAuthority = () => {
	switch (safeEnv('ENVIRONMENT')?.toLowerCase()) {
		case 'production':
			return 'https://woonstadb2c.b2clogin.com/49b5c8ba-7bd9-4882-ae64-671081d0323a/v2.0/';
		case 'acceptance':
			return 'https://woonstadb2ctst.b2clogin.com/81015d2c-553a-4fb5-9bb0-9743fff71e43/v2.0/';
		case 'test':
			return 'https://woonstadb2ctst.b2clogin.com/81015d2c-553a-4fb5-9bb0-9743fff71e43/v2.0/';
		default:
			return '';
	}
};

const getKnownAuthorities = () => {
	switch (safeEnv('ENVIRONMENT')?.toLowerCase()) {
		case 'production':
			return ['woonstadb2c.b2clogin.com', 'woonstadb2c.onmicrosoft.com'];
		case 'acceptance':
			return ['woonstadb2ctst.b2clogin.com', 'woonstadb2ctst.onmicrosoft.com'];
		case 'test':
			return ['woonstadb2ctst.b2clogin.com', 'woonstadb2ctst.onmicrosoft.com'];
		default:
			return [''];
	}
};

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export const msalConfig = (): Configuration => {
	const origin = safeWindow.location?.origin ?? 'https://mijn.woonstadrotterdam.nl';

	return {
		auth: {
			clientId: getClientId(),
			redirectUri: '/',
			postLogoutRedirectUri: `${origin}${Paths.LOGGED_OUT}`,
			authority: getAuthority(),
			knownAuthorities: getKnownAuthorities(), // Mark your B2C tenant's domain as trusted.
			skipAuthorityMetadataCache: true,
			authorityMetadata: JSON.stringify(getWellKnownJson()),
			navigateToLoginRequestUrl: true,
		},
		system: {
			allowNativeBroker: false, // Disables WAM Broker
		},
		cache: {
			cacheLocation: 'localStorage',
		},
	};
};

export const loginRequest: RedirectRequest = {
	scopes: [
		// Adding the clientId tells AzureAD to give an access token
		getClientId(),
		'offline_access',
		'openid',
	],
	authority: getAuthority(),
	extraQueryParameters: {
		ui_locales: 'nl', // for now Dutch only
	},
};

export const parseMsalUrl = (url: string) => {
	const newURL = new URL(url);
	const params = new URLSearchParams(newURL.search);

	const currentQueryParams = new URLSearchParams(safeWindow.location?.search);
	const clientId = currentQueryParams.get('client_id');
	if (clientId) {
		params.set('client_id', clientId);
	}

	const redirectUri = currentQueryParams.get('redirect_uri');
	if (redirectUri) {
		params.set('redirect_uri', redirectUri);
	}

	params.set('ui_locales', localLng());
	newURL.search = params.toString();

	return newURL.toString();
};
