import Box from 'components/box/Box';
import useMedia from 'hooks/useMedia';
import { FunctionComponent, ReactNode } from 'react';
import styles from './CenterBoxedContainer.module.scss';
import { Header } from 'components/header/Header';

interface Props {
	children?: ReactNode;
}

const CenterBoxedContainer: FunctionComponent<Props> = ({ children }) => {
	const [mobile] = useMedia('(max-width: 575.98px)');

	return (
		<div className={styles.root}>
			<div
				className={styles.background}
				aria-hidden
			>
				<div className={styles.backgroundShapes}>
					<span className={styles.bal} />
					<span className={styles.bal} />
					<span className={styles.bal} />
					<span className={styles.bal} />
				</div>
			</div>

			<div className={styles.headerWrapper}>
				<Header />
			</div>

			<main className={styles.content}>
				<Box
					variant='dark'
					noShadow={mobile}
					noPadding
					className={styles.box}
				>
					{children}
				</Box>
			</main>
		</div>
	);
};

export default CenterBoxedContainer;
