import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const CancelContract = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10 34H30V12L26.99 8.99L24 6H16H10V12V34Z'
				fill='#D9F4D8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M24 6L27 9L30 12H24V6Z'
				fill='#60D15D'
			/>
			<path
				d='M22 11.48H12V12.53H22V11.48Z'
				fill='#00215B'
			/>
			<path
				d='M21 14.48H12V15.53H21V14.48Z'
				fill='#00215B'
			/>
			<path
				d='M22.71 22.48H12V23.53H22.71V22.48Z'
				fill='#00215B'
			/>
			<path
				d='M18 25.48H12V26.53H18V25.48Z'
				fill='#00215B'
			/>
			<path
				d='M33 20L30 17L25.5 21.5L21 17L18 20L22.5 24.5L18 29L21 32L25.5 27.5L30 32L33 29L28.5 24.5L33 20Z'
				fill='#60D15D'
			/>
		</svg>
	);
};

export default CancelContract;
