import clsx from 'clsx';
import {
	MouseEvent,
	useCallback,
	useMemo,
	useState,
	FunctionComponent,
	SVGProps,
	ReactNode,
} from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './ButtonPrimary.scss';
import Anchor, { AnchorProps } from 'components/anchor/Anchor';
import parseLink from 'utils/parseLink';
import { LocationDescriptor } from 'history';

interface Props extends Pick<AnchorProps, 'disableModal' | 'target' | 'rel' | 'id'> {
	children?: ReactNode;
	color?: 'ice-soft' | 'pink' | 'error';
	type?: 'button' | 'submit';
	icon: FunctionComponent<SVGProps<SVGSVGElement>>;
	disabled?: boolean;
	className?: string;
	onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
	link?: LocationDescriptor;
	target?: string;
	testid?: string;
}

/**
 * Primary button component.
 *
 * Visually it is the button with the background and the animating icon
 */
const ButtonPrimary = ({
	icon: Icon,
	children,
	disabled,
	color = 'ice-soft',
	type = 'button',
	className,
	onClick,
	link,
	target,
	testid,
	...restProps
}: Props) => {
	const [hover, setHover] = useState<boolean>(false);
	const classes = clsx('button-primary', `button-primary--color-${color}`, className, {
		'button-primary--children': children !== undefined,
		'button-primary--hover': hover,
		'button-primary--disabled': disabled,
	});

	const { isExternalLink, pathname } = parseLink(link);

	const onEnter = useCallback(() => {
		if (!disabled) setHover(true);
	}, [setHover, disabled]);

	const onLeave = useCallback(() => {
		if (!disabled) setHover(false);
	}, [setHover, disabled]);

	const content = useMemo(
		() => (
			<>
				<span className='button-primary__label'>{children}</span>
				<span className='button-primary__icon-container'>
					<CSSTransition
						in={hover}
						appear={false}
						classNames='button-primary__icon--transition'
						timeout={250}
						unmountOnExit
						mountOnEnter
					>
						<Icon className='button-primary__icon button-primary__icon--hover' />
					</CSSTransition>
					<CSSTransition
						in={hover}
						appear={false}
						classNames='button-primary__icon--transition'
						timeout={250}
					>
						<Icon className='button-primary__icon button-primary__icon--default' />
					</CSSTransition>
				</span>
			</>
		),
		[hover, children, Icon],
	);

	if (pathname !== undefined && !isExternalLink) {
		return (
			<Link
				to={pathname}
				className={classes}
				onClick={onClick}
				onMouseEnter={onEnter}
				onMouseLeave={onLeave}
				data-testid={testid}
				{...restProps}
			>
				{content}
			</Link>
		);
	}

	if (link !== undefined && isExternalLink) {
		return (
			<Anchor
				href={pathname}
				target={target}
				onClick={onClick}
				{...restProps}
				onMouseEnter={onEnter}
				onMouseLeave={onLeave}
				className={classes}
				data-testid={testid}
			>
				{content}
			</Anchor>
		);
	}

	return (
		<button
			className={classes}
			onMouseEnter={onEnter}
			onMouseLeave={onLeave}
			disabled={disabled}
			onClick={onClick}
			type={type}
			data-testid={testid}
			{...restProps}
		>
			{content}
		</button>
	);
};

export default ButtonPrimary;
