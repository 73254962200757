import Box from 'components/box/Box';
import ButtonLink from 'components/button-link/ButtonLink';
import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import Typography from 'components/typography/Typography';
import { FunctionComponent, useCallback } from 'react';
import { Trans } from 'react-i18next';
import styles from './BeforeUnloadModal.module.scss';
import { ENGLISH_TO_WSR_STORAGE_KEY, ENGLISH_TO_WSR_STORAGE_VALUE } from '../../../../Shared.Frontend/constants/index';
import safeWindow from 'utils/safeWindow';
import safeLocalStorage from 'utils/safeLocalstorage';

interface Props {
	href: string;
	target?: string;
	handleClose: () => void;
}

const BeforeUnloadModal: FunctionComponent<Props> = ({ handleClose, href, target = '_self' }) => {
	const linkToWoonstadRotterdam = useCallback(() => {
		safeLocalStorage.setItem?.(ENGLISH_TO_WSR_STORAGE_KEY, ENGLISH_TO_WSR_STORAGE_VALUE);
		safeWindow.open?.(href, target);
	}, [href, target]);

	return (
		<Box
			className={styles.root}
			noShadow
			noPadding
		>
			<div className={styles.contentContainer}>
				<Typography
					variant='block-title'
					className={styles.title}
				>
					<Trans>This link takes you to pages which are only available in Dutch</Trans>
				</Typography>
				<Typography variant='body-small'>
					<Trans>
						If you decide to continue, you’ll be redirected to woonstadrotterdam.nl, which is not available in English.
					</Trans>
				</Typography>
				<div className={styles.actionContainer}>
					<ButtonLink onClick={handleClose}>
						<Trans>Stay in this part of the website</Trans>
					</ButtonLink>
					<ButtonPrimary
						icon={ArrowForward}
						onClick={linkToWoonstadRotterdam}
						disableModal
					>
						<Trans>Go to woonstadrotterdam.nl</Trans>
					</ButtonPrimary>
				</div>
			</div>
		</Box>
	);
};

export default BeforeUnloadModal;
