import clsx from 'clsx';
import Anchor, { AnchorProps } from 'components/anchor/Anchor';
import { MouseEvent, ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import './ButtonText.scss';
import { LocationDescriptor } from 'history';
import parseLink from 'utils/parseLink';

interface Props extends Pick<AnchorProps, 'disableModal' | 'target' | 'rel' | 'id'> {
	children?: ReactNode;
	disabled?: boolean;
	className?: string;
	onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
	link?: LocationDescriptor;
	size?: 'normal' | 'small' | 'extra-small';
	noUnderline?: boolean;
}

const ButtonText = ({
	children,
	disabled,
	className,
	size,
	noUnderline = false,
	onClick,
	link,
	...restProps
}: Props) => {
	const classes = clsx('button-text', className, {
		'button-text--small': size === 'small',
		'button-text--extra-small': size === 'extra-small',
		'button-text--no-underline': noUnderline,
	});
	const content = useMemo(() => <span className='button-text__label'>{children}</span>, [children]);
	const { isExternalLink, pathname } = parseLink(link);
	const isTelOrMail = pathname?.startsWith('tel:') || pathname?.startsWith('mailto:');

	if (pathname !== undefined && !isExternalLink && !isTelOrMail) {
		return (
			<Link
				to={pathname}
				className={classes}
				{...restProps}
			>
				{content}
			</Link>
		);
	}

	if (pathname !== undefined && isExternalLink || isTelOrMail) {
		return (
			<Anchor
				href={pathname}
				className={classes}
				{...restProps}
			>
				{content}
			</Anchor>
		);
	}

	return (
		<button
			className={classes}
			disabled={disabled}
			onClick={onClick}
			{...restProps}
		>
			{content}
		</button>
	);
};

export default ButtonText;
