import clsx from 'clsx';
import HelperText from 'components/helper-text/HelperText';
import Error from 'assets/svg/error.svg?react';
import Password from 'assets/svg/password.svg?react';
import PasswordHide from 'assets/svg/password-hide.svg?react';
import Success from 'assets/svg/success.svg?react';
import {
	ChangeEvent,
	ComponentPropsWithoutRef,
	FocusEvent,
	forwardRef,
	InputHTMLAttributes,
	lazy,
	ElementType,
	Suspense,
	useCallback,
	useMemo,
	useState,
	KeyboardEvent,
	FunctionComponent,
	SVGProps,
} from 'react';
import MaskedInput from 'react-text-mask';
import './Input.scss';

const PasswordStrength = lazy(() => import('components/password-strength/PasswordStrength'));

export interface InputProps extends ComponentPropsWithoutRef<ElementType> {
	type?: 'text' | 'password' | 'email';
	value?: string | number;
	className?: string;
	placeholder?: string;
	id?: string;
	name?: string;
	disabled?: boolean;
	disableAutocomplete?: boolean;
	valid?: boolean;
	helperText?: string;
	hideValidateIcon?: boolean;
	hidePasswordStrength?: boolean;
	inputElement?: 'input' | typeof MaskedInput;
	inputElementProps?: InputHTMLAttributes<HTMLInputElement>;
	icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
	onIconClick?: () => void;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
	onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
	testid?: string;
}

const Input = forwardRef<HTMLElement, InputProps>(
	(
		{
			value,
			className,
			placeholder,
			id,
			name,
			onChange,
			valid,
			helperText,
			onBlur,
			onFocus,
			onKeyDown,
			inputElement: InputElement = 'input',
			inputElementProps,
			icon: Icon,
			disableAutocomplete = false,
			disabled = false,
			hideValidateIcon = false,
			hidePasswordStrength = false,
			type = 'text',
			onIconClick,
			testid,
		},
		ref,
	) => {
		const [passwordReveal, setPasswordReveal] = useState<boolean>(false);
		const [focus, setFocus] = useState<boolean>(false);
		const onRevealClick = useCallback(() => setPasswordReveal(!passwordReveal), [setPasswordReveal, passwordReveal]);
		const onInnerBlur = useCallback(
			(event: ChangeEvent<HTMLInputElement>) => {
				setFocus(false);
				if (onBlur !== undefined) {
					onBlur(event);
				}
			},
			[onBlur],
		);
		const onInnerFocus = useCallback(
			(event: FocusEvent<HTMLInputElement>) => {
				setFocus(true);
				if (onFocus !== undefined) {
					onFocus(event);
				}
			},
			[onFocus],
		);
		const classes = clsx('input', className, {
			'input--focus': focus,
			'input--disabled': disabled,
			'input--success': valid !== undefined && valid,
			'input--error': valid !== undefined && !valid,
			'input--has-validation-icon': !hideValidateIcon && valid !== undefined,
			'input--has-icon': Icon !== undefined,
			'input--type-password': type === 'password',
		});
		const iconClasses =
			Icon !== undefined ?
				clsx('input__icon', {
					'input__icon--cursor': onIconClick !== undefined,
				}) : undefined;
		const inputElementClasses = clsx('input__field', {
			'input__password': type === 'password',
		});
		const helperTextView = useMemo(() => {
			if (helperText !== undefined) {
				const status = valid === undefined ? undefined : valid ? 'success' : 'error';
				const classes = clsx('input__helper-text', {
					[`input__helper-text--${status}`]: status !== undefined,
				});
				return (
					<HelperText
						className={classes}
						id={id}
					>
						{helperText}
					</HelperText>
				);
			}

			return undefined;
		}, [helperText, valid, id]);

		return (
			<span
				className={classes}
				ref={ref}
			>
				<span className='input__inner'>
					{/* @ts-ignore - this error does not make sense?! */}
					<InputElement
						{...inputElementProps}
						data-testid={testid}
						id={id}
						name={name}
						type={passwordReveal ? 'text' : type}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						className={inputElementClasses}
						onFocus={onInnerFocus}
						onBlur={onInnerBlur}
						onKeyDown={onKeyDown}
						disabled={disabled}
						autoComplete={disableAutocomplete ? 'off' : 'on'}
					/>
					{type === 'password' && !passwordReveal && (
						<Password
							className='input__toggle-password'
							onClick={onRevealClick}
						/>
					)}
					{type === 'password' && passwordReveal && (
						<PasswordHide
							className='input__toggle-password'
							onClick={onRevealClick}
						/>
					)}
					{!hideValidateIcon && valid !== undefined && valid && <Success className='input__validation-icon' />}
					{!hideValidateIcon && valid !== undefined && !valid && <Error className='input__validation-icon' />}
					{Icon !== undefined && <Icon
						className={iconClasses}
						onClick={onIconClick}
					/>}
				</span>
				{!hidePasswordStrength && value && type === 'password' && (
					<Suspense fallback=''>
						<PasswordStrength
							password={String(value)}
							className='input__password-strength'
						/>
					</Suspense>
				)}
				{helperTextView}
			</span>
		);
	},
);

Input.displayName = 'Input';

export default Input;
