import { CustomerInfo } from '../../../Shared.Frontend/generated-portal-woonstad/models/CustomerInfo';
import { Person } from '../../../Shared.Frontend/generated-portal-woonstad/models/Person';

export const createName = (customer?: CustomerInfo) => {
	const start = customer?.firstname || customer?.initials;
	return [start, customer?.lastnamePrefix, customer?.lastname].filter((val) => val).join(' ');
};

export const createNamePerson = (person?: Person) => {
	return [person?.firstName, person?.lastNamePrefix, person?.lastName].filter((val) => val).join(' ');
};
