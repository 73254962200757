import { useState, useEffect } from 'react';
import useShouldShowPrompt from './useShouldShowPrompt';
import safeWindow from 'utils/safeWindow';

const webInstallPromptedAt = 'webInstallPromptedAt';

const useWebInstallPrompt = (): [any, () => void, () => void] => {
	const [installPromptEvent, setInstallPromptEvent] = useState<null | undefined | Event | any>();
	const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] = useShouldShowPrompt(webInstallPromptedAt);

	useEffect(() => {
		const beforeInstallPromptHandler = (event: Event) => {
			event.preventDefault();

			// check if user has already been asked
			if (userShouldBePromptedToInstall) {
				// store the event for later use
				setInstallPromptEvent(event);
			}
		};

		safeWindow.addEventListener?.('beforeinstallprompt', beforeInstallPromptHandler);
		return () => safeWindow.removeEventListener?.('beforeinstallprompt', beforeInstallPromptHandler);
	}, [userShouldBePromptedToInstall]);

	const handleInstallDeclined = () => {
		handleUserSeeingInstallPrompt();
		setInstallPromptEvent(null);
	};

	const handleInstallAccepted = () => {
		// show native prompt
		if (installPromptEvent) {

			installPromptEvent.prompt();

			// decide what to do after the user chooses
			installPromptEvent.userChoice.then((choice: any) => {
				// if the user declined, we don't want to show the prompt again
				if (choice.outcome !== 'accepted') {
					handleUserSeeingInstallPrompt();
				}

				setInstallPromptEvent(null);
			});
		}
	};

	return [installPromptEvent, handleInstallDeclined, handleInstallAccepted];
};

export default useWebInstallPrompt;
