import clsx from 'clsx';
import styles from './AzureContainer.module.scss';
import Typography from 'components/typography/Typography';
import ButtonLink from 'components/button-link/ButtonLink';
import ArrowBackward from 'assets/svg/arrow-backward.svg?react';
import Logo from 'components/logo/Logo';
import { ReactNode } from 'react';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import { getExternalLink } from 'utils/getExternalLink';

export type Props = {
	children: ReactNode;
	title?: string;
	backLink?: string;
	backText?: string;
	variant?: 'single';
	env?: string;
	testid?: string;
}

const AzureContainer = ({
	children,
	title,
	backLink,
	backText,
	variant,
	env,
	testid,
}: Props) => {
	const boxClasses = clsx(styles.box, {
		[`${styles[`box--variant-${variant}`]}`]: variant !== undefined,
	});

	return (
		<div className={styles.login}>
			<div className={styles.root}>
				<div className={styles.background}>
					<div className={styles.backgroundShapes}>
						<span className={styles.bal} />
						<span className={styles.bal} />
						<span className={styles.bal} />
						<span className={styles.bal} />
					</div>
				</div>

				<div className={styles.header}>
					<div className={styles.headerContent}>
						{(backLink && backText) ? (
							<ButtonLink
								size='large'
								iconLeft={ArrowBackward}
								link={backLink}
								contrast
								rel='noreferrer'
								className='replace-client-id-link replace-scopes'
							>
								{backText}
							</ButtonLink>
						) : (
							<span />
						)}
						<span className={styles.right}>
							<Logo />
						</span>
					</div>
				</div>
				<div className={styles.content}>
					<div className={boxClasses}>
						<div className={'grid-1-lg'}>
							{title && (
								<Typography
									type='h1'
									variant='page-title'
									color='white'
									data-testid={testid}
								>
									{title}
								</Typography>
							)}
							{children}
						</div>
					</div>
				</div>
				<nav className={styles.bottomNavigation}>
					<ButtonLink
						className={styles.bottomNavigationItem}
						iconRight={ArrowForward}
						hoverAnimation='bounce-right'
						fontWeight='normal'
						link={getExternalLink('LOGIN_PARTNER', env)}
					>
						Voor partners
					</ButtonLink>
					<ButtonLink
						className={styles.bottomNavigationItem}
						iconRight={ArrowForward}
						hoverAnimation='bounce-right'
						fontWeight='normal'
						link={getExternalLink('LOGIN_EMPLOYEE', env)}
					>
						Voor medewerkers
					</ButtonLink>
				</nav>
			</div>
		</div>
	);
};

export default AzureContainer;
