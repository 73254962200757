import { useHistory } from 'react-router-dom';
import { useContractById, useContracts } from './store/useContracts';
import { useSessionStorage } from './useSessionStorage';
import { useEffect } from 'react';
import { Paths } from 'constants/routes';
import { ValueOf } from '../../../Shared.Frontend/types/utils';

/**
 * This hook is used to get the contract to use for the flow. Use it on all the pages that require a contract to have been chosen.
 */
export const useContractToUse = (redirectToPath?: ValueOf<typeof Paths>) => {
	const [contractInStorage, setContractInStorage] = useSessionStorage<string | undefined>('chosen-contractId', undefined);

	const { contracts: allContracts, requesting, success, isError } = useContracts();
	const { contract: contractByStorage } = useContractById(contractInStorage ?? '');
	const contractToUse = contractByStorage ?? allContracts?.[0];

	const history = useHistory();

	// If the contract in storage is not valid, remove it from storage
	useEffect(() => {
		if (!allContracts || allContracts.length === 0) return;

		const isContractInStorageValid = allContracts.some((contract) => contract.id?.toString() === contractInStorage);

		if (!isContractInStorageValid) {
			setContractInStorage(undefined);
		}
	}, [allContracts, contractInStorage, setContractInStorage]);

	// If no contract is found, redirect to the given path to choose a contract
	useEffect(() => {
		if (requesting || !success || !redirectToPath) {
			return;
		}

		if (!contractByStorage) {
			history.push(redirectToPath);
		}
	}, [allContracts?.length, contractByStorage, history, redirectToPath, requesting, success]);

	return {
		contractToUse,
		setContractInStorage,
		requesting,
		success,
		isError,
	};
};

