import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const PaymentPlan = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M28.84 26.32C28.25 26.91 27.3 26.91 26.72 26.32C27.31 26.91 27.31 27.86 26.72 28.44C26.13 29.03 25.18 29.03 24.6 28.44C25.19 29.03 25.19 29.98 24.6 30.56C24.01 31.15 23.06 31.15 22.48 30.56C23.07 31.15 23.07 32.1 22.48 32.68C21.89 33.27 20.94 33.27 20.36 32.68L19.68 32L10.82 23.14L9.82 20.12C9.56 19.33 9.11 18.6 8.52 18.01L7.28 16.77L12.94 11.11L14.17 12.34C14.76 12.93 15.48 13.37 16.26 13.63L19.3 14.64L28.14 23.48L28.49 23.83L28.84 24.18C29.43 24.77 29.43 25.72 28.84 26.3V26.32Z'
				fill='#60D15D'
			/>
			<path
				d='M3.03 13.95L9.39 7.59L10.1 6.88L13.64 10.42L6.57 17.49L3.03 13.95ZM17.53 29.15L17.88 28.8C18.47 28.21 18.47 27.26 17.88 26.68C17.29 26.09 16.34 26.09 15.76 26.68L16.29 26.15C16.78 25.66 16.7 24.79 16.11 24.21C15.52 23.63 14.65 23.55 14.17 24.03L13.29 24.91C13.88 24.32 13.88 23.37 13.29 22.79C12.7 22.2 11.75 22.2 11.17 22.79L10.46 23.5C9.87 24.09 9.87 25.04 10.46 25.62C11.05 26.21 12 26.21 12.58 25.62L12.4 25.8C11.91 26.29 11.99 27.16 12.58 27.74C13.17 28.33 14.04 28.4 14.52 27.92L14.7 27.74C14.11 28.33 14.11 29.28 14.7 29.86C15.29 30.45 16.24 30.45 16.82 29.86C16.23 30.45 16.23 31.4 16.82 31.98C17.41 32.57 18.36 32.57 18.94 31.98L19.65 31.27C20.24 30.68 20.24 29.73 19.65 29.15C19.06 28.57 18.11 28.56 17.53 29.15ZM29.2 9L26.52 11.68C25.96 12.24 25.26 12.62 24.48 12.77L21.58 12.62C20.58 12.57 19.6 12.94 18.89 13.65L15.41 17.13L15.06 17.48L14.71 17.83C14.12 18.42 14.12 19.37 14.71 19.95C15.3 20.53 16.25 20.54 16.83 19.95L17.18 19.6L18.88 17.9C19.14 17.64 19.53 17.55 19.88 17.66L23.22 18.77C23.44 18.84 23.63 18.96 23.79 19.13L28.49 23.83L30.27 22.05C30.5 21.82 30.65 21.54 30.71 21.23L31.08 19.36C31.23 18.59 31.61 17.88 32.17 17.32L34.85 14.64L29.19 8.98L29.2 9Z'
				fill='#B3E9B1'
			/>
			<path
				d='M26.36 10.41L33.44 17.48L36.97 13.95L36.26 13.24L29.9 6.88L26.36 10.41Z'
				fill='#60D15D'
			/>
			<path
				d='M14.1426 9.15148L7.64432 15.6498L8.35142 16.3569L14.8497 9.85859L14.1426 9.15148Z'
				fill='#00215B'
			/>
			<path
				d='M22.18 18.73C21.47 18.73 20.74 18.57 20.04 18.26L20.45 17.35C21.71 17.92 23.14 17.84 24.1 17.14C24.82 16.62 25.16 15.84 25.06 14.96L26.05 14.85C26.19 16.09 25.69 17.22 24.68 17.95C23.97 18.47 23.08 18.73 22.16 18.73H22.18Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default PaymentPlan;
