import { ContractType } from '../../../../Shared.Frontend/constants/index';
import { FormBlockType } from 'pages/dashboard/types';
import { Paths } from 'constants/routes';
import { paymentMethodName } from 'constants/payments';
import House from './icons/main-flows/House';
import Paint from './icons/main-flows/Paint';
import Repair from './icons/main-flows/Repair';
import ServiceContract from './icons/main-flows/ServiceContract';
import Key from './icons/main-flows/Key';
import Nametag from './icons/main-flows/Nametag';
import ExclamationMark from './icons/main-flows/ExclamationMark';
import Money from './icons/payments/Money';
import Calendar from './icons/payments/Calendar';
import Heat from './icons/payments/Heat';
import RentDecrease from './icons/payments/RentDecrease';
import PaymentMethod from './icons/payments/PaymentMethod';
import PaymentPlan from './icons/payments/PaymentPlan';
import RentIncrease from './icons/payments/RentIncrease';
import CancelContract from './icons/payments/CancelContract';
import ContractCheck from './icons/payments/ContractCheck';
import Household from './icons/household/Household';
import AddRoommate from './icons/household/AddRoommate';
import Documents from './icons/documents/Documents';
import Parking from './icons/main-flows/Parking';
import Invoices from './icons/invoices/Invoices';
import Business from './icons/main-flows/Business';
import { ValueOf } from '../../../../Shared.Frontend/types/utils';
import { getExternalLink } from 'utils/getExternalLink';

type ContractTypeMap = Record<
	ContractType,
	{
		tabs: Array<{
			title: string;
			testid?: string;
			icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
			path: ValueOf<typeof Paths>;
			formBlocksCol1: Array<FormBlockType>;
			formBlocksCol2: Array<FormBlockType>;
		}>;
	}
>

export const DASHBOARD_CONTRACT_TYPE_MAP: ContractTypeMap = {
	[ContractType.HOME]: {
		tabs: [
			{
				title: 'In en om mijn woning',
				testid: 'woonruimteTab',
				icon: House,
				path: Paths.DASHBOARD_WOONRUIMTE,
				formBlocksCol1: [
					{
						type: 'collection',
						title: 'Reparaties & klussen',
						testid: 'reparatiesEnKlussenTitle',
						inner: [
							{
								type: 'ingediende-reparaties',
								when: 'always',
							},
							{
								type: 'buttons',
								title: null,
								buttons: [
									{
										title: 'Reparatie aanvragen',
										testid: 'repairRequestLink',
										icon: Repair,
										link: getExternalLink('REPARATION_REQUEST'),
										when: 'always',
									},
									{
										title: 'Klussen in je woning',
										testid: 'klussenInJeWoningLink',
										icon: Paint,
										link: Paths.KLUSSEN_IN_JE_WONING,
										when: 'always',
									},
									{
										title: 'Serviceabonnement',
										testid: 'serviceContractLink',
										icon: ServiceContract,
										link: (contract) => contract?.hasServiceContract ? undefined : Paths.SERCIVE_SUBSCRIPTION,
										subtitle: (contract) => contract?.hasServiceContract ? 'Aan' : 'Uit',
										when: ({ flowAvailability }): boolean => {
											return flowAvailability?.find((flow) => flow.name === 'serviceSubscription')?.available ?? true;
										},
									},
								],
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'buttons',
						title: 'Sleutel en naamplaatje',
						testid: 'sleutelEnNaamplaatjeTitle',
						buttons: [
							{
								title: 'Sleutel aanvragen',
								testid: 'requestKeyLink',
								icon: Key,
								link: Paths.REQUEST_KEY,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestKey')?.available ?? true;
								},
							},
							{
								title: 'Naamplaatje aanvragen',
								testid: 'requestNametagLink',
								icon: Nametag,
								link: Paths.REQUEST_NAMETAG,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestNametag')?.available ?? true;
								},
							},
						],
					},
					{
						type: 'buttons',
						title: 'Klacht of overlast',
						testid: 'klachtOfOverlastTitle',
						buttons: [
							{
								title: 'Klacht of overlast melden',
								testid: 'complaintOrAnnoyanceLink',
								icon: ExclamationMark,
								link: getExternalLink('COMPLAINT_OR_ANNOYANCE'),
								when: 'always',
							},
							{
								title: 'Verkeerd gebruik woning melden',
								testid: 'woonfraudeMeldenLink',
								icon: ExclamationMark,
								link: Paths.WOONFRAUDE_MELDEN,
								when: 'always',
							},
						],
					},
				],
			},
			{
				title: 'Huur en betalingen',
				testid: 'huurBetalingenTab',
				icon: Money,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Huur betalen',
						testid: 'huurBetalenTitle',
						buttons: [
							{
								title: 'Betaalmanier',
								testid: 'paymentMethodLink',
								subtitle: (contract) => paymentMethodName(contract?.paymentMethod),
								icon: PaymentMethod,
								link: () => undefined,
								when: 'always',
							},
							{
								title: 'Betaaldatum wijzigen',
								testid: 'changeDirectDebitDateLink',
								icon: Calendar,
								link: Paths.CHANGE_DIRECT_DEBIT_DATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'changeDirectDebitDate')?.available ?? true;
								},
							},
							{
								// @TODO This availability is not in sync with logic implemented in AdvancementAdjusted.tsx line 120
								title: 'Voorschot stookkosten wijzigen',
								testid: 'advancementAdjustmentLink',
								icon: Heat,
								link: (contract) => Paths.ADVANCEMENT_ADJUSTMENT.replace(':contractId', String(contract.id)),
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'adjustAdvancement')?.available ?? true;
								},
							},
							{
								title: 'Betalingsregeling aanvragen',
								testid: 'requestPaymentPlanLink',
								icon: PaymentPlan,
								link: Paths.PAYMENT_PLAN,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestPaymentPlan')?.available ?? true;
								},
							},
							{
								title: 'Huurverlaging aanvragen',
								testid: 'requestRentReductionLink',
								icon: RentDecrease,
								link: Paths.RENT_REDUCTION_REQUEST,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestRentReduction')?.available ?? true;
								},
							},
							{
								title: 'Bezwaar maken huuraanpassing',
								testid: 'opposeRentIncreaseLink',
								icon: RentIncrease,
								link: Paths.RENT_INCREASE_OBJECTION,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'opposeRentIncrease')?.available ?? true;
								},
							},
							// {
							// 	title: 'Wijzigen bankrekening',
							// 	icon: Pencil,
							// 	link: Paths.CHANGE_DIRECT_DEBIT_DATE,
							// 	when: 'always',
							// },
							// {
							// 	title: 'AcceptEasy wisselen',
							// 	icon: CreditCheck,
							// 	link: Paths.CHANGE_DIRECT_DEBIT_DATE,
							// 	when: 'always',
							// },
						],
					},
					{
						type: 'buttons',
						title: 'Opzeggen en verhuurdersverklaring',
						testid: 'opzeggenEnVerhuurdersverklaringTitle',
						buttons: [
							{
								title: 'Huur opzeggen',
								testid: 'cancelContractLink',
								icon: CancelContract,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
							{
								title: 'Verhuurdersverklaring aanvragen',
								testid: 'requestRentalReferenceLetterLink',
								icon: ContractCheck,
								link: Paths.RENTAL_REFERENCE_LETTER,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestRentalReferenceLetter')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: 'Mijn huishouden',
				testid: 'huishoudenTab',
				icon: Household,
				path: Paths.DASHBOARD_HUISHOUDEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Wijziging doorgeven',
						testid: 'wijzigingDoorgevenTitle',
						buttons: [
							{
								title: 'Medebewoner inschrijven',
								testid: 'addRoommateLink',
								icon: AddRoommate,
								link: Paths.ADD_ROOMMATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'addRoommate')?.available ?? true;
								},
							},
							// {
							// 	title: 'Afstandsverklaring',
							// 	icon: HouseLeave,
							// 	link: Paths.DASHBOARD,
							// 	when: 'always',
							// },
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'bewoners',
						when: 'always',
					},
				],
			},
			{
				title: 'Belangrijke documenten',
				testid: 'documentenTab',
				icon: Documents,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.PARKING]: {
		tabs: [
			{
				title: 'In en om mijn parkeerplaats',
				testid: 'parkeerplaatsTab',
				icon: Parking,
				path: Paths.DASHBOARD_PARKEERPLAATS,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Klacht of overlast',
						testid: 'klachtOfOverlastTitle',
						buttons: [
							{
								title: 'Klacht of overlast melden',
								testid: 'complaintOrAnnoyanceLink',
								icon: ExclamationMark,
								link: getExternalLink('COMPLAINT_OR_ANNOYANCE'),
								when: 'always',
							},
						],
					},
				],
				formBlocksCol2: [],
			},
			{
				title: 'Huur & betalingen',
				testid: 'huurBetalingenTab',
				icon: Money,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Huur betalen',
						testid: 'huurBetalenTitle',
						buttons: [
							{
								title: 'Betaaldatum wijzigen',
								testid: 'changeDirectDebitDateLink',
								icon: Calendar,
								link: Paths.CHANGE_DIRECT_DEBIT_DATE,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'changeDirectDebitDate')?.available ?? true;
								},
							},
							{
								title: 'Betalingsregeling aanvragen',
								testid: 'requestPaymentPlanLink',
								icon: PaymentPlan,
								link: Paths.PAYMENT_PLAN,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'requestPaymentPlan')?.available ?? true;
								},
							},
						],
					},
					{
						type: 'buttons',
						title: 'Opzeggen',
						testid: 'opzeggenTitle',
						buttons: [
							{
								title: 'Huur opzeggen',
								testid: 'cancelContractLink',
								icon: CancelContract,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: 'Belangrijke documenten',
				testid: 'documentenTab',
				icon: Documents,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.BUSINESS]: {
		tabs: [
			{
				title: 'In en om mijn bedrijfsruimte',
				testid: 'businessTab',
				icon: Business,
				path: Paths.DASHBOARD_BUSINESS,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Reparaties',
						testid: 'reparatiesTitle',
						buttons: [
							{
								title: 'Reparatie aanvragen',
								testid: 'repairRequestLink',
								icon: Repair,
								link: getExternalLink('REPARATION_REQUEST_BUSINESS'),
								when: 'always',
							},
						],
					},
				],
				formBlocksCol2: [],
			},
			{
				title: 'Huur & betalingen',
				testid: 'huurBetalingenTab',
				icon: Money,
				path: Paths.DASHBOARD_HUUR_BETALINGEN,
				formBlocksCol1: [
					{
						type: 'buttons',
						title: 'Opzeggen',
						testid: 'opzeggenTitle',
						buttons: [
							{
								title: 'Huur opzeggen',
								testid: 'cancelContractLink',
								icon: CancelContract,
								link: Paths.CANCEL_CONTRACT,
								when: ({ flowAvailability }): boolean => {
									return flowAvailability?.find((flow) => flow.name === 'cancelContract')?.available ?? true;
								},
							},
						],
					},
				],
				formBlocksCol2: [
					{
						type: 'betalingen',
						when: 'always',
					},
				],
			},
			{
				title: 'Facturen',
				testid: 'facturenTab',
				icon: Invoices,
				path: Paths.DASHBOARD_FACTUREN,
				formBlocksCol1: [
					{
						type: 'facturen',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
			{
				title: 'Belangrijke documenten',
				testid: 'documentenTab',
				icon: Documents,
				path: Paths.DASHBOARD_DOCUMENTEN,
				formBlocksCol1: [
					{
						type: 'documenten',
						when: 'always',
					},
				],
				formBlocksCol2: [],
			},
		],
	},
	[ContractType.OWNER]: { tabs: [] },
};
