import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DataService } from '../../../../Shared.Frontend/generated-portal-woonstad/services/DataService';

export const useDataConfig = () => {
	const { data, isLoading } = useQuery({
		queryKey: ['data-config'],
		queryFn: () => DataService.dataConfig(),
	});

	return { data, isLoading };
};

type UseOnlineAfspraken = (key: 'medebewoneraanmelden' | 'sleutelaanvragen' | 'huuropzeggen') => string | undefined;

export const useOnlineAfspraken: UseOnlineAfspraken = (key) => {
	const { data: config } = useDataConfig();

	const url = useMemo(() => {
		const enabled = config?.onlineafspraken && config?.onlineafspraken[key] !== undefined ? config?.onlineafspraken[key] === 'true' : undefined;
		const url =
			config?.onlineafspraken && config?.onlineafspraken[`${key}_url`] !== undefined
				? config?.onlineafspraken[`${key}_url`]?.trim()
				: undefined;
		return enabled ? url : undefined;
	}, [config?.onlineafspraken, key]);

	return url;
};

export const useShowSwitch = () => {
	const { data: config, isLoading } = useDataConfig();

	if (isLoading) return false;

	if (config?.translations.showSwitch === 'false') {
		return false;
	}

	return true;
};
