import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const RentDecrease = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M22.6 26.1C21.65 26.66 20.59 26.96 19.49 26.96C16.62 26.96 14.1 24.91 13.17 21.97H19.97V16.98H13.17C14.1 14.04 16.62 11.99 19.49 11.99C21.23 11.99 22.86 12.73 24.12 14.07L27.76 10.66C25.64 8.4 22.72 7.01 19.49 7.01C13.83 7.01 9.12 11.3 8.03 16.99H5V21.98H8.03C9.11 27.67 13.83 31.96 19.49 31.96C21.72 31.96 23.79 31.28 25.56 30.13L22.6 26.11V26.1Z'
				fill='#B3E9B1'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26 25H23L29 33L35 25H32V16H26V25Z'
				fill='#60D15D'
			/>
			<path
				d='M20.54 28.32C16.19 28.32 12.65 24.78 12.65 20.43H13.65C13.65 24.23 16.74 27.32 20.54 27.32V28.32Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default RentDecrease;
