import ButtonText from 'components/button-text/ButtonText';
import InfoBlue from 'assets/svg/rich/info-blue.svg?react';
import './Footer.scss';
import styles from './Footer.module.scss';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import { getExternalLink } from 'utils/getExternalLink';
import { TextLink, Typography } from '@woonstad/design-system';

export const Footer = () => {
	return (
		<footer className={styles.root}>
			<div className={styles.wrapper}>
				<div className={styles.helpWrapper}>
					<InfoBlue />
					<div className={styles.helpTextWrapper}>
						<Typography
							variant='title-body'
							textColorClassname='text-base-primary-on-light'
						>
							Kunnen we je helpen?
						</Typography>
						<TextLink
							iconRight={ArrowForward}
							size='small'
						>
							<a href={getExternalLink('CONTACT')}>Neem contact met ons op</a>
						</TextLink>
					</div>
				</div>
				<div className='footer__secondary-links'>
					<span>&copy; {new Date().getFullYear()}{' '}
						<ButtonText
							size='extra-small'
							noUnderline
							link={getExternalLink('WEBSITE')}
						>
							Woonstad Rotterdam
						</ButtonText>
					</span>
					&minus;
					<ButtonText
						size='extra-small'
						noUnderline
						link={getExternalLink('PRIVACY_STATEMENT')}
					>
						Privacy Statement
					</ButtonText>
					&minus;
					<ButtonText
						size='extra-small'
						noUnderline
						link={getExternalLink('USER_CONSENT_DISCLAIMER')}
					>
						Disclaimer
					</ButtonText>
				</div>
			</div>
		</footer>
	);
};
