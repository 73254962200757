import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Heat = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M24.03 16.02C28 19.99 28.95 25.85 25.73 29.06C22.52 32.27 16.69 31.66 12.72 27.69L24.02 16.02H24.03Z'
				fill='#D9F4D8'
			/>
			<path
				d='M11.79 16.9C12.13 16.44 12.85 16.69 12.89 17.26C12.98 18.46 13.5 20.11 15.43 21.4C15.43 21.4 12.33 11.05 19.9 6.1C20.27 5.86 20.74 6.21 20.62 6.63C20.25 7.94 20.15 10.09 22.3 12.24C25.7 15.64 30.22 17.92 30.31 23.52C30.39 28.65 26.86 33 21.8 33.83C15.28 34.9 9.72 29.98 9.72 23.68C9.72 23.68 9.81 19.54 11.8 16.89L11.79 16.9ZM18.11 26.86C17.39 27.41 16.53 27.88 15.55 28.14C15 28.29 14.9 29.01 15.35 29.35C16.92 30.55 18.97 31.14 21.16 30.77C24.27 30.25 26.78 27.71 27.27 24.59C27.78 21.3 26.15 18.35 23.56 16.88C24.17 17.97 24.53 19.22 24.53 20.56C24.53 23.64 22.67 26.28 20.02 27.43C19.9 27.48 21.89 25.85 20.91 22.39C20.18 24.79 19.47 25.82 18.11 26.85V26.86Z'
				fill='#60D15D'
			/>
			<path
				d='M22.77 17.61C19.57 14.41 19.57 9.19999 22.77 6.00999L23.48 6.71999C20.67 9.52999 20.67 14.1 23.48 16.91L22.77 17.62V17.61Z'
				fill='#00215B'
			/>
			<path
				d='M12.35 23.96C11.15 22.76 10.48 21.16 10.48 19.45C10.48 17.74 11.14 16.15 12.35 14.94L13.06 15.65C10.97 17.74 10.97 21.15 13.06 23.25L12.35 23.96Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Heat;
