import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Password = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M16 13C16 11.9391 16.4214 10.9217 17.1716 10.1716C17.9217 9.42143 18.9391 9 20 9C21.0609 9 22.0783 9.42143 22.8284 10.1716C23.5786 10.9217 24 11.9391 24 13V20H27V13C27 11.1435 26.2625 9.36301 24.9497 8.05025C23.637 6.7375 21.8565 6 20 6C18.1435 6 16.363 6.7375 15.0503 8.05025C13.7375 9.36301 13 11.1435 13 13H16Z'
				fill='#FF7842'
			/>
			<path
				d='M28.87 16H11.13C10.5059 16 10 16.5059 10 17.13V32.87C10 33.4941 10.5059 34 11.13 34H28.87C29.4941 34 30 33.4941 30 32.87V17.13C30 16.5059 29.4941 16 28.87 16Z'
				fill='#FF7842'
			/>
			<path
				d='M20 26C21.6569 26 23 24.6569 23 23C23 21.3431 21.6569 20 20 20C18.3431 20 17 21.3431 17 23C17 24.6569 18.3431 26 20 26Z'
				fill='#FFBFA5'
			/>
			<path
				d='M21 24H19L18 30H22L21 24Z'
				fill='#FFBFA5'
			/>
			<path
				d='M23 24C22.9975 24.5665 22.8347 25.1208 22.5303 25.5986C22.2259 26.0764 21.7924 26.4583 21.28 26.7L22 31'
				stroke='#00215B'
				strokeMiterlimit='10'
			/>
			<path
				d='M8.49998 22.6V18.93C8.49866 18.7821 8.52666 18.6354 8.58235 18.4983C8.63805 18.3613 8.72033 18.2366 8.82447 18.1316C8.9286 18.0265 9.05252 17.9431 9.18906 17.8862C9.3256 17.8293 9.47206 17.8 9.61998 17.8H14.5'
				stroke='#00215B'
				strokeMiterlimit='10'
			/>
		</svg>
	);
};

export default Password;
