import { TWO_DECIMALS_FORMAT } from '../../../Shared.Frontend/constants/index';
import numeral from 'numeral';
import { Locale } from 'constants/locale';
import { Money } from '../../../Shared.Frontend/generated-portal-woonstad/models/Money';

/**
 * @deprecated use formatNumber instead
 */
export const formatNumberValue = (value: number | string) => numeral(value).format('0,0');
export const formatNumber = (value?: number | string | Money) => numeral(value).format(TWO_DECIMALS_FORMAT);

export const initializeDutchLocale = () => {
	numeral.register('locale', Locale.NL, {
		delimiters: {
			thousands: '.',
			decimal: ',',
		},
		abbreviations: {
			thousand: 'k',
			million: 'mln',
			billion: 'mrd',
			trillion: 'bln',
		},
		ordinal: function (number) {
			const remainder = number % 100;
			return (number !== 0 && remainder <= 1) || remainder === 8 || remainder >= 20 ? 'ste' : 'de';
		},
		currency: {
			symbol: '€',
		},
	});
	numeral.locale(Locale.NL);
};

export default numeral;
