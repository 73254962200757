import { Paths } from 'constants/routes';
import { useRouteMatch } from 'react-router';

export const usePwa = () => {
	const paths = [
		Paths.DASHBOARD,
		Paths.NOTIFICATIONS,
		Paths.USER_PROFILE,
		Paths.ADD_ROOMMATE,
		Paths.ADD_ROOMMATE_CONFIRMATION,
		Paths.ADD_ROOMMATE_ERROR,
		Paths.SERCIVE_SUBSCRIPTION,
		Paths.SERCIVE_SUBSCRIPTION_CONFIRMATION,
		Paths.HOME_VALUATION_POINTS,
		Paths.CANCEL_CONTRACT,
		Paths.CANCEL_CONTRACT_CONFIRMATION,
		Paths.CANCEL_CONTRACT_ERROR,
		Paths.PAYMENT_PLAN,
		Paths.PAYMENT_PLAN_SUCCESS,
		Paths.PAYMENT_PLAN_ERROR,
		Paths.CHANGE_DIRECT_DEBIT_DATE,
		Paths.CHANGE_DIRECT_DEBIT_DATE_CONFIRMATION,
		Paths.CHANGE_DIRECT_DEBIT_DATE_ERROR,
		Paths.REQUEST_KEY,
		Paths.REQUEST_KEY_PAYMENT_RETURN,
		Paths.CHANGE_MAINTENANCE_APPOINTMENT,
		Paths.CHANGE_MAINTENANCE_APPOINTMENT_ERROR,
		Paths.CHANGE_MAINTENANCE_APPOINTMENT_SUCCESS,
		Paths.MAIL_PREFERENCES,
		Paths.RENTAL_REFERENCE_LETTER,
		Paths.RENTAL_REFERENCE_LETTER_START,
		Paths.RENTAL_REFERENCE_LETTER_SUCCESS,
		Paths.RENTAL_REFERENCE_LETTER_ERROR,
		Paths.RENT_REDUCTION_REQUEST,
		Paths.RENT_REDUCTION_REQUEST_SUCCESS,
		Paths.RENT_REDUCTION_REQUEST_ERROR,
	];

	const matchRoutes = useRouteMatch({
		path: paths,
		exact: true,
		strict: true,
	});

	return matchRoutes !== null ? true : false;
};
