type Props = {
	className?: string;
}

const Repair = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.92001 23.88C8.63001 22.17 11.05 21.6 13.23 22.17L22.17 13.23C21.59 11.06 22.17 8.64 23.88 6.92C25.59 5.2 28.01 4.64 30.19 5.21L26.18 9.22L30.78 13.82L34.79 9.81C35.37 11.98 34.79 14.4 33.08 16.12C31.37 17.84 28.95 18.4 26.77 17.83L17.83 26.77C18.41 28.94 17.83 31.36 16.12 33.08C14.41 34.8 11.99 35.36 9.81001 34.79L13.82 30.78L9.22001 26.18L5.21001 30.19C4.63001 28.02 5.21001 25.6 6.92001 23.88Z'
				fill='#F170B8'
			/>
			<path
				d='M23.6492 17.6435L14.6477 26.645L15.3548 27.3521L24.3563 18.3506L23.6492 17.6435Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Repair;
