import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const House = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='80'
			height='80'
			viewBox='0 0 80 80'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 30L40 14L62 30V66H18V30Z'
				fill='#F170B8'
			/>
			<path
				d='M40 42H26V66H40V42Z'
				fill='#F8BADD'
			/>
			<path
				d='M44 62H42V46H30V44H44V62Z'
				fill='#00215B'
			/>
			<path
				d='M13.18 31.62L12 30L34 14L42 20L40.8 21.6L33.98 16.48L13.18 31.62Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default House;
