import ButtonLink from 'components/button-link/ButtonLink';
import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import Close from 'assets/svg/close.svg?react';
import WoonstadLogo from 'assets/svg/rich/woonstad-logo.svg?react';
import Typography from 'components/typography/Typography';
import { usePwa } from 'hooks/app/usePwa';
import useUserAgent from 'hooks/useUserAgent';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import useWebInstallPrompt from '../../hooks/useWebInstallPrompt';
import styles from './InstallPwa.module.scss';

const InstallPwa: FunctionComponent = () => {
	const isAuthenticated = usePwa();
	const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] = useWebInstallPrompt();
	const [, device] = useUserAgent();
	const isMobile = device !== undefined && device.type === 'mobile';

	return (
		<>
			{isAuthenticated && webInstallPrompt && isMobile && (
				<div className={styles.prompt}>
					<div className={styles.content}>
						<div className={styles.logo}>
							<WoonstadLogo />
						</div>
						<div>
							<Typography
								variant='body'
								color='navy'
								fontWeight='bold'
								className={styles.contentText}
							>
								<Trans>MijnWoonstad op je startscherm</Trans>
							</Typography>
							<Typography
								variant='body'
								color='navy'
								className={styles.contentText}
							>
								<Trans>Wil je de volgende keer gelijk ingelogd zijn? Installeer de app.</Trans>
							</Typography>
						</div>
					</div>
					<div className={styles.footer}>
						<ButtonPrimary
							icon={ArrowForward}
							onClick={handleWebInstallAccepted}
							data-gtm-category='User interaction'
							data-gtm-action='pwa prompt'
							data-gtm-label='Installeren'
						>
							<Trans>Installeren</Trans>
						</ButtonPrimary>
						<ButtonLink
							iconRight={Close}
							onClick={handleWebInstallDeclined}
							data-gtm-category='User interaction'
							data-gtm-action='pwa prompt'
							data-gtm-label='Sluiten'
						><Trans>Sluiten</Trans>
						</ButtonLink>
					</div>
				</div>
			)}
		</>
	);
};

export default InstallPwa;
