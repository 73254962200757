import { OpenAPIConfig } from '../../../Shared.Frontend/generated-portal-woonstad/core/OpenAPI';
import { OpenAPI as OpenAPIPortalBE } from '../../../Shared.Frontend/generated-portal-woonstad/core/OpenAPI';
import { OpenAPI as OpenAPIContentDeliveryWRF } from '../../../Shared.Frontend/generated-content-delivery-wrf/core/OpenAPI';
import { OpenAPI as OpenAPIMaintenance } from '../../../Shared.Frontend/generated-maintenance/core/OpenAPI';
import { OpenAPI as OpenAPIAvailability } from '../../../Shared.Frontend/generated-availability/core/OpenAPI';
import { OpenAPI as OpenAPIAccount } from '../../../Shared.Frontend/generated-account/core/OpenAPI';
import { OpenAPI as OpenAPITrackAndTrace } from '../../../Shared.Frontend/generated-track-and-trace/core/OpenAPI';
import { OpenAPI as OpenAPIPayment } from '../../../Shared.Frontend/generated-payment/core/OpenAPI';
import { OpenAPI as OpenAPIPortal } from '../../../Shared.Frontend/generated-portal/core/OpenAPI';

export const openAPISet = <Property extends keyof OpenAPIConfig>(
	property: Property,
	value: { gateway: OpenAPIConfig[Property], okapi: OpenAPIConfig[Property] },
) => {
	OpenAPIPortalBE[property] = value.gateway;
	OpenAPIContentDeliveryWRF[property] = value.gateway;
	OpenAPIMaintenance[property] = value.gateway;
	OpenAPIAvailability[property] = value.gateway;
	OpenAPIAccount[property] = value.gateway;
	OpenAPITrackAndTrace[property] = value.okapi;
	OpenAPIPayment[property] = value.gateway;
	OpenAPIPortal[property] = value.gateway;
};

export const openAPIMutate = <Property extends keyof OpenAPIConfig>(
	property: Property,
	mutator: (value: OpenAPIConfig[Property]) => { gateway: OpenAPIConfig[Property], okapi: OpenAPIConfig[Property] },
) => {
	OpenAPIPortalBE[property] = mutator(OpenAPIPortalBE[property]).gateway;
	OpenAPIContentDeliveryWRF[property] = mutator(OpenAPIContentDeliveryWRF[property]).gateway;
	OpenAPIMaintenance[property] = mutator(OpenAPIMaintenance[property]).gateway;
	OpenAPIAvailability[property] = mutator(OpenAPIAvailability[property]).gateway;
	OpenAPIAccount[property] = mutator(OpenAPIAvailability[property]).gateway;
	OpenAPITrackAndTrace[property] = mutator(OpenAPITrackAndTrace[property]).okapi;
	OpenAPIPayment[property] = mutator(OpenAPIPayment[property]).gateway;
	OpenAPIPortal[property] = mutator(OpenAPIPortal[property]).gateway;
};
