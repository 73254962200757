import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Money = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M35 12H5V28H35V12Z'
				fill='#60D15D'
			/>
			<path
				d='M15.61 21.2H14.17V18.8H15.61C16.12 16.06 18.36 14 21.05 14C22.58 14 23.97 14.67 24.97 15.76L23.24 17.4C22.65 16.76 21.87 16.4 21.04 16.4C19.68 16.4 18.48 17.39 18.04 18.8H21.36V21.2H18.04C18.48 22.61 19.68 23.6 21.04 23.6C21.86 23.6 22.64 23.24 23.24 22.6L24.97 24.24C23.97 25.33 22.58 26 21.05 26C18.37 26 16.13 23.94 15.61 21.2Z'
				fill='#B3E9B1'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.25 28C8.25 26.21 6.79 24.75 5 24.75V28H8.25Z'
				fill='#B3E9B1'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5 15.25C6.79 15.25 8.25 13.79 8.25 12H5V15.25Z'
				fill='#B3E9B1'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M31.75 12C31.75 13.79 33.21 15.25 35 15.25V12H31.75Z'
				fill='#B3E9B1'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M35 24.75C33.21 24.75 31.75 26.21 31.75 28H35V24.75Z'
				fill='#B3E9B1'
			/>
			<path
				d='M14.17 30.03H6.12V24H7.12V29.03H14.17V30.03Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Money;
