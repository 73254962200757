import { PublicClientApplication } from '@azure/msal-browser';
import safeWindow from './safeWindow';

export const redirectActionKey = 'redirectAction';

export const redirectActions = {
	renewToken: 'renewToken',
	renewTokenOnPaymentSuccess: 'renewTokenOnPaymentSuccess',
} as const;

/**
 * Initiates checks for redirect actions.
 * Intention is that redirectActions can be left in sessionstorage, and this function will handle them upon initial page load.
 *
 * For example when a user has payed the "inschrijfgeld" - a new token should be retreived as it is then became a customer.
 *
 * @param msalInstance The msal intance
 */
export const onRedirectActions = async (msalInstance: PublicClientApplication) => {
	if (safeWindow.sessionStorage?.getItem(redirectActionKey) === redirectActions.renewToken) {
		await msalInstance.initialize();

		// The local session is killed but the server still has it going
		msalInstance.logoutRedirect({
			onRedirectNavigate: () => {
				// Return false if you would like to stop navigation after local logout
				return false;
			},
		});

		safeWindow.sessionStorage?.removeItem(redirectActionKey);
	}

	if (safeWindow.sessionStorage?.getItem(redirectActionKey) === redirectActions.renewTokenOnPaymentSuccess) {
		const searchParams = new URLSearchParams(safeWindow.location?.search);
		const result = searchParams.get('result') as 'success' | 'failure' | null;

		if (result === 'success') {
			await msalInstance.initialize();

			// The local session is killed but the server still has it going
			await msalInstance.logoutRedirect({
				onRedirectNavigate: () => {
					// Return false if you would like to stop navigation after local logout
					return false;
				},
			});

			safeWindow.sessionStorage?.removeItem(redirectActionKey);
		}
	}
};
