import { SelectOption } from 'components/select/Select';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { ContractType } from '../../../../Shared.Frontend/constants/index';
import { useQuery } from '@tanstack/react-query';
import { contractTypeTitle } from 'constants/contract';
import { ContractService } from '../../../../Shared.Frontend/generated-portal-woonstad/services/ContractService';
import { ContractListItem } from '../../../../Shared.Frontend/generated-portal-woonstad/models/ContractListItem';
import { useIsAuthenticated } from '@azure/msal-react';

const endDateFilter = (contract: ContractListItem) => {
	if (contract.endDate !== null) {
		return dayjs(contract.endDate).isAfter(dayjs());
	}

	return true;
};

export const useGetContracts = () => {
	const isAuthenticated = useIsAuthenticated();

	const query = useQuery({
		queryKey: ['contracts'],
		queryFn: ContractService.contractList,
		enabled: isAuthenticated,
	});

	return query;
};

export const useContracts = () => {
	const { data, isSuccess, isLoading, error, isError } = useGetContracts();
	return {
		success: isLoading ? undefined : isSuccess && !data?.error && !isError,
		isError: isError,
		requesting: isLoading,
		error: data?.error || error,
		contracts: data?.contracts,
	};
};

export const useContractById = (id: string | number) => {
	const { success, contracts } = useContracts();

	const contract = useMemo(() => (
		contracts && contracts.find((contract) => String(contract.id) === id)
	), [contracts, id]);

	return { success, contract };
};

export const useContractOptions = () => {
	const { success, contracts } = useContracts();

	const options = useMemo(() => (
		contracts !== undefined
			? contracts
				.filter(endDateFilter)
				.map((contract) => ({
					value: String(contract.id),
					label: `${contractTypeTitle(contract.type)} - ${contract.address}`,
				}), [])
			: []
	), [contracts]);

	return { success, options };
};

export const useSelectContractTypeOptions = () => {
	const { contracts } = useContracts();

	const options = useMemo(() => (
		contracts !== undefined
			? contracts
				.filter(endDateFilter)
				.reduce<SelectOption[]>((acc, contract) => {
					const typeLabel = contractTypeTitle(contract.type);
					return [...acc, { value: String(contract.id), label: `${typeLabel} - ${contract.address}` }];
				}, [])
			: []
	), [contracts]);

	return options;
};

export const useSelectHomeContractOptions = () => {
	const { contracts } = useContracts();

	const options = useMemo(() => (
		contracts !== undefined
			? contracts
				.filter(endDateFilter)
				.reduce<SelectOption[]>((acc, contract) => {
					if (contract.type === ContractType.HOME) {
						const typeLabel = contractTypeTitle(contract.type);
						return [...acc, { value: String(contract.id), label: `${typeLabel} - ${contract.address}` }];
					}

					return acc;
				}, [])
			: []
	), [contracts]);

	return options;
};
