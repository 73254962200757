import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

type UseUserAgent = () => [UAParser.IBrowser | undefined, UAParser.IDevice | undefined];

const useUserAgent: UseUserAgent = () => {
	const [browser, setBrowser] = useState<UAParser.IBrowser>();
	const [device, setDevice] = useState<UAParser.IDevice>();
	useEffect(() => {
		const parser = new UAParser();
		setBrowser(parser.getBrowser());
		setDevice(parser.getDevice());
	}, []);
	return [browser, device];
};

export default useUserAgent;
