import i18next from 'i18next';
import * as Yup from 'yup';

export const validationSchema = (currentEmail: string) => {
	return Yup.object().shape({
		email: Yup.string()
			.email(
				i18next.t(
					'Er is geen geldig e-mailadres ingevuld. Controleer of het e-mailadres een @ en een punt bevat en of er geen spaties in staan.',
				),
			)
			.required(i18next.t('Vul dit veld in.'))
			.notOneOf([currentEmail], i18next.t('Voer een nieuw email adres in')),
		emailRepeat: Yup.string().email(
			i18next.t(
				'Er is geen geldig e-mailadres ingevuld. Controleer of het e-mailadres een @ en een punt bevat en of er geen spaties in staan.',
			),
		).required(i18next.t('Vul dit veld in.')).oneOf([Yup.ref('email')], i18next.t('E-mailadressen komen niet overeen')),
	});
};
