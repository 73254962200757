import { Paths } from 'constants/routes';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAccount } from '@azure/msal-react';
import { identityChosen } from 'pages/choose-account/useChooseAccount';
import { useCookies } from 'react-cookie';
import { openAPIMutate } from 'utils/openAPI';

export interface MultiCustomerLocation {
	multicustomer?: boolean;
	initialRoutePath?: string | null;
	contractId?: string | number | undefined;
	logout?: boolean | undefined;
}

const useMultiCustomerRouting = () => {
	const location = useLocation<MultiCustomerLocation>();
	const history = useHistory<MultiCustomerLocation>();
	const account = useAccount();

	const query = new URLSearchParams(location.search);
	const callback = query.get('callback');

	const [cookie] = useCookies(['customer-id']);

	const customerId = cookie['customer-id'];

	useEffect(() => {
		// Need to add customer id to both header as well as cookie
		// The documents are hosted on the same domain as website, and only served via direct GET request so header is not possible. Using cookie for this scenario
		// For all the API calls (via gateway), we use header. This is required because setting a cookie for the gateway domain is not possible from website domain
		openAPIMutate('HEADERS', (currentHeaders) => ({ gateway: { ...currentHeaders, 'customer-id': String(customerId ?? '') }, okapi: {} }));
	}, [customerId]);

	useEffect(() => {
		if (identityChosen || location.pathname === Paths.CHOOSE_ACCOUNT) return;
		const customerIdString = account?.idTokenClaims?.extension_CustomerIds;
		const customerIds = customerIdString?.split(',');

		if (cookie['customer-id'] && customerIds?.includes(String(cookie['customer-id']))) {
			return;
		}

		const fromRoute = location.pathname + location.search;

		if (customerIds && customerIds.length > 1) {
			history.replace(`${Paths.CHOOSE_ACCOUNT}?from=${fromRoute}`);
			return;
		}
	}, [history, location.pathname, location.search, callback, account, cookie]);
};

export default useMultiCustomerRouting;
