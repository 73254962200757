import Box from 'components/box/Box';
import Layout from 'components/layout/Layout';
import Typography from 'components/typography/Typography';
import { Trans, useTranslation } from 'react-i18next';
import classes from '../ChangeEmailMultiUser.module.scss';
import { useConditionalLocation } from 'hooks/useConditionalRedirect';
import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import User from 'assets/svg/user.svg?react';
import { safeEnv } from 'utils/safeEnv';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import { useUserProfile } from 'hooks/store/useUserProfile';
import { useLogoutAction } from 'hooks/useLogout';

const ConfirmationAccountNotKnown = ({ emailAddress }: { emailAddress?: string }) => {
	const { t } = useTranslation();
	const { name } = useUserProfile();
	const logout = useLogoutAction();

	return (
		<>
			<Typography
				variant='body'
				color='navy'
				dangerouslySetInnerHTML={t('De gegevens van gebruiker <b>{{name}}</b> zijn vanaf nu bij ons bekend onder e-mailadres <b>{{emailAddress}}</b>.', { name, emailAddress })}
			/>

			<Typography
				variant='body'
				color='navy'
				dangerouslySetInnerHTML={t('Om toegang te krijgen tot de gegevens van {{name}}, <b>maak je een eigen MijnWoonstad account aan</b> voor deze gebruiker.', { name })}
			/>

			<Typography
				variant='body'
				color='navy'
				dangerouslySetInnerHTML={t('Gebruik daarbij het e-mailadres {{emailAddress}}.', { emailAddress })}
			/>

			<div>
				<ButtonPrimary
					icon={ArrowForward}
					target='_blank'
					link={safeEnv('B2C_CREATE_ACCOUNT')}
					onClick={logout}
				>
					<Trans>Account aanmaken</Trans>
				</ButtonPrimary>
			</div>
		</>
	);
};

const ConfirmationAccountKnown = ({ emailAddress }: { emailAddress?: string }) => {
	const { t } = useTranslation();
	const logout = useLogoutAction();

	return (
		<>
			<Typography
				variant='body-small'
				color='navy'
				dangerouslySetInnerHTML={t('Je kunt vanaf nu inloggen bij MijnWoonstad met e-mailadres {{email}}.', { email: emailAddress })}
			/>
			<div>
				<ButtonPrimary
					color='pink'
					icon={User}
					onClick={logout}
				>
					<Trans>Inloggen bij MijnWoonstad</Trans>
				</ButtonPrimary>
			</div>
		</>
	);
};

const ChangeEmailMultiUserConfirmation = () => {
	const { state: locationState } = useConditionalLocation<{ emailAddress?: string, accountExists?: boolean, name?: string; }>();

	return (
		<Layout title='E-mailadres wijzigen'>
			<Box
				className={classes.noBorder}
				noPadding
			>
				<Box
					className={`${classes.noBorder} grid-1-lg`}
					noShadow
				>
					<Typography
						type='h2'
						variant='block-title'
						color='navy'
					>
						<Trans>Je e-mailadres is gewijzigd</Trans>
					</Typography>

					{locationState?.accountExists ? (
						<ConfirmationAccountKnown emailAddress={locationState?.emailAddress} />
					) : (
						<ConfirmationAccountNotKnown emailAddress={locationState?.emailAddress} />
					)}

				</Box>
			</Box>
		</Layout>
	);
};

export default ChangeEmailMultiUserConfirmation;
