import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Household = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M26.85 20C30.1637 20 32.85 17.3137 32.85 14C32.85 10.6863 30.1637 8 26.85 8C23.5363 8 20.85 10.6863 20.85 14C20.85 17.3137 23.5363 20 26.85 20Z'
				fill='#0061CD'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.85 20C30.16 20 32.85 22.69 32.85 26V34H20.85V26C20.85 22.69 23.54 20 26.85 20Z'
				fill='#0061CD'
			/>
			<path
				d='M13.15 18C16.4637 18 19.15 15.3137 19.15 12C19.15 8.68629 16.4637 6 13.15 6C9.83628 6 7.14999 8.68629 7.14999 12C7.14999 15.3137 9.83628 18 13.15 18Z'
				fill='#85BDED'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13.15 18C16.46 18 19.15 20.69 19.15 24V34H7.14999V24C7.14999 20.69 9.83999 18 13.15 18Z'
				fill='#85BDED'
			/>
			<path
				d='M24.63 19.02C22.41 19.02 20.61 17.22 20.61 15H21.61C21.61 16.67 22.96 18.02 24.63 18.02C26.3 18.02 27.65 16.67 27.65 15H28.65C28.65 17.22 26.85 19.02 24.63 19.02Z'
				fill='#00215B'
			/>
			<path
				d='M13.15 17.02C10.93 17.02 9.13 15.22 9.13 13H10.13C10.13 14.67 11.48 16.02 13.15 16.02C14.82 16.02 16.17 14.67 16.17 13H17.17C17.17 15.22 15.37 17.02 13.15 17.02Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Household;
