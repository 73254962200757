import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import safeWindow from 'utils/safeWindow';

const useScrollRestoration = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		safeWindow.scrollTo?.(0, 0);
	}, [pathname]);
};

export default useScrollRestoration;
