import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const RentIncrease = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M22.6 13.82C21.65 13.26 20.59 12.96 19.49 12.96C16.62 12.96 14.1 15.02 13.17 17.97H19.97V22.98H13.17C14.1 25.93 16.62 27.99 19.49 27.99C21.23 27.99 22.86 27.25 24.12 25.91L27.76 29.34C25.64 31.61 22.72 33.01 19.49 33.01C13.83 33.01 9.12 28.71 8.03 22.99H5V17.98H8.03C9.11 12.26 13.83 7.95999 19.49 7.95999C21.72 7.95999 23.79 8.63999 25.56 9.79999L22.6 13.84V13.82Z'
				fill='#B3E9B1'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M32 15H35L29 7L23 15H26V24H32V15Z'
				fill='#60D15D'
			/>
			<path
				d='M13.5 20.39H12.5C12.5 16.04 16.04 12.5 20.39 12.5V13.5C16.59 13.5 13.5 16.59 13.5 20.39Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default RentIncrease;
