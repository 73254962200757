import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const AddRoommate = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M16.37 19C19.6837 19 22.37 16.3137 22.37 13C22.37 9.68629 19.6837 7 16.37 7C13.0563 7 10.37 9.68629 10.37 13C10.37 16.3137 13.0563 19 16.37 19Z'
				fill='#85BDED'
			/>
			<path
				d='M17.4 17.5C15.18 17.5 13.38 15.7 13.38 13.48H14.38C14.38 15.15 15.74 16.5 17.4 16.5C19.06 16.5 20.42 15.15 20.42 13.48H21.42C21.42 15.7 19.62 17.5 17.4 17.5Z'
				fill='#00215B'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.4 19.03C19.71 19.03 22.4 21.72 22.4 25.03V33H10.4V25.03C10.4 21.72 13.09 19.03 16.4 19.03Z'
				fill='#85BDED'
			/>
			<path
				d='M26 17C29.3137 17 32 14.3137 32 11C32 7.68629 29.3137 5 26 5C22.6863 5 20 7.68629 20 11C20 14.3137 22.6863 17 26 17Z'
				fill='#0061CD'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26 17C29.31 17 32 19.69 32 23V33H20V23C20 19.69 22.69 17 26 17Z'
				fill='#0061CD'
			/>
			<path
				d='M30 21C31.1046 21 32 20.1046 32 19C32 17.8954 31.1046 17 30 17C28.8954 17 28 17.8954 28 19C28 20.1046 28.8954 21 30 21Z'
				fill='#85BDED'
			/>
			<path
				d='M26 15.49C23.78 15.49 21.98 13.69 21.98 11.47H22.98C22.98 13.14 24.34 14.49 26 14.49C27.66 14.49 29.02 13.14 29.02 11.47H30.02C30.02 13.69 28.22 15.49 26 15.49Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default AddRoommate;
