import safeLocalStorage from 'utils/safeLocalstorage';
import { LANGUAGE_STORAGE_KEY } from '../../../Shared.Frontend/constants/index';
import dayjs from 'dayjs';

export enum Locale {
	NL = 'nl-NL',
	EN = 'en-US'
}

/**
 * https://day.js.org/docs/en/display/format
 */

export const dayMonthFormat = () => {
	switch (dayjs.locale()) {
		case 'en':
			return 'MMMM D';
		case 'nl':
		default:
			return 'D MMMM';
	}
};

export const setLocale = (locale = 'nl') => {
	switch (locale) {
		case Locale.EN:
		case 'en':
			dayjs.locale('en');
			break;
		case Locale.NL:
		case 'nl':
		default:
			dayjs.locale('nl');
			break;
	}
};

export const localLng = (): string => {
	const storageLng = safeLocalStorage.getItem?.(LANGUAGE_STORAGE_KEY);
	if (storageLng) {
		switch (storageLng) {
			case Locale.EN:
			case 'en':
				setLocale('en');
				return Locale.EN;
			case Locale.NL:
			case 'nl':
			default:
				setLocale('nl');
				return Locale.NL;
		}
	}

	setLocale('nl');
	return Locale.NL;
};
