import { ContractRouteParams } from 'model/contracts';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Paths } from 'constants/routes';
import { useContractById, useContracts } from './useContracts';
import { ContractType } from '../../../../Shared.Frontend/constants/index';
import { ContractListItem } from '../../../../Shared.Frontend/generated-portal-woonstad/models/ContractListItem';
import { useQuery } from '@tanstack/react-query';
import { ContractService } from '../../../../Shared.Frontend/generated-portal-woonstad/services/ContractService';
import { DocumentService } from '../../../../Shared.Frontend/generated-portal-woonstad/services/DocumentService';
import { ContractInfo } from '../../../../Shared.Frontend/generated-portal-woonstad/models/ContractInfo';

export const paymentMethodMap: Record<NonNullable<ContractInfo['paymentMethod']>, string> = {
	0: 'UNKNOWN',
	1: 'DIRECT_DEBIT',
	2: 'GIRO_PAYMENT_SLIP',
	3: 'PORTAL', // Not used but appears in model fsr
} as const;

export const useGetContractInfo = (id?: number | string) => {
	const { data, isLoading, isError } = useQuery({
		queryKey: [`contract-info-${id}`],
		queryFn: () => ContractService.contractDetails(Number(id)),
		enabled: !!id,
		retry: false,
	});
	return {
		success: data?.success,
		isError: isError,
		requesting: isLoading,
		contract: data?.contract,
	};
};

export const useContractInvoices = (id?: string | number, from?: string, to?: string) => {
	const { data, isLoading, isSuccess, isError, refetch } = useQuery({
		queryKey: [`contract-invoices-${id}`],
		queryFn: () => DocumentService.documentGetInvoices(Number(id), from, to),
		enabled: !!id,
	});
	return {
		success: isSuccess,
		error: isError,
		requesting: isLoading,
		invoices: data,
		refetch,
	};
};

export const useGetContractDocuments = (id?: string | number) => {
	const { data, isLoading, isSuccess } = useQuery({
		queryKey: [`contract-documents-${id}`],
		queryFn: () => DocumentService.documentSelection(Number(id)),
		enabled: !!id,
	});

	return {
		success: isSuccess,
		requesting: isLoading,
		documents: data,
	};
};

const firstContractId = (contracts?: ContractListItem[]) => {
	if (contracts) {
		const filteredContracts = { ...contracts };
		if (filteredContracts.length > 1) {
			const homeContracts = filteredContracts.filter((contract) => {
				return contract.type === ContractType.HOME;
			});
			if (homeContracts.length !== 0) {
				return homeContracts[0]?.id;
			} else {
				return filteredContracts[0]?.id;
			}
		} else {
			return filteredContracts[0]?.id;
		}
	} else {
		return null;
	}
};

export const useNoContractRedirect = () => {
	const { contractId } = useParams<ContractRouteParams>();
	const { success, contract } = useContractById(contractId);
	const history = useHistory();
	const location = useLocation();
	const { contracts } = useContracts();
	const contractIdFromState = firstContractId(contracts);
	useEffect(() => {
		if (contractIdFromState !== null && contractIdFromState !== undefined && contractId === 'id') {
			history.replace(location.pathname.replace('id', String(contractIdFromState)));
		} else if (success !== undefined && contract === undefined && contractId !== 'id') {
			history.replace(Paths.DASHBOARD);
		}
	}, [contract, success, history, contractId, location, contractIdFromState]);
};
