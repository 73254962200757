import clsx from 'clsx';
import { DataTestID } from 'model';
import { AriaAttributes, FunctionComponent, ReactNode } from 'react';
import styles from './Typography.module.scss';

export interface TypographyProps extends DataTestID, AriaAttributes {
	className?: string;
	fontWeight?: 'normal' | 'bold' | '400' | '500' | '600' | '700' | '800' | '900';
	dangerouslySetInnerHTML?: string;
	children?: ReactNode;
	type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'label' | 'span';
	font?: 'apercu' | 'arboria';
	variant:
	| 'page-title-large'
	| 'page-title'
	| 'block-title'
	| 'button-label'
	| 'body-title'
	| 'body'
	| 'body-small'
	| 'form-label'
	| 'form-description'
	| 'tag'
	| 'uppercase-tag';
	color?:
	| 'navy'
	| 'blue'
	| 'body-text'
	| 'white'
	| 'ice'
	| 'cobalt'
	| 'tags'
	| 'error'
	| 'green'
	| 'red'
	| 'success'
	| 'pink'
	| 'orange';
	htmlFor?: string;
	id?: string;
	siblingSpacing?: boolean;
}

const Typography: FunctionComponent<TypographyProps> = ({
	children,
	className,
	fontWeight = 'normal',
	type = 'p',
	font,
	variant,
	color,
	siblingSpacing = false,
	dangerouslySetInnerHTML,
	htmlFor,
	id,
	...dataAttributes
}) => {
	const classes = clsx(styles.typography, styles[`typography--variant-${variant}`], className, {
		[styles['typography--sibling-spacing']]: siblingSpacing,
		[styles[`typography--color-${color}`]]: color,
		[styles[`typography--font-${font}`]]: font,
		[styles[`typography--font-weight-${fontWeight}`]]: fontWeight !== undefined && fontWeight !== 'normal',
	});
	const Component = htmlFor !== undefined ? 'label' : type;

	if (dangerouslySetInnerHTML) {
		return (
			<Component
				{...dataAttributes}
				id={id}
				className={classes}
				dangerouslySetInnerHTML={{ __html: dangerouslySetInnerHTML }}
				htmlFor={htmlFor}
			/>
		);
	}

	return (
		<Component
			{...dataAttributes}
			id={id}
			className={classes}
			htmlFor={htmlFor}
		>
			{children}
		</Component>
	);
};

export default Typography;
