import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Contract = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5 18.9394L14 13L23 18.9394V34H5V18.9394Z'
				fill='#FF7842'
			/>
			<path
				d='M35 8H19V30H35V8Z'
				fill='#FFBFA5'
			/>
			<path
				d='M14 26H9V34H14V26Z'
				fill='#FFBFA5'
			/>
			<path
				d='M22 12.5V11.5H32V12.5H22ZM22 16.5V15.5H32V16.5H22ZM27.5 24H28.5V26.5H22V25.5H27.5V24ZM21.7227 21.416L12.7227 15.416L13.2774 14.584L22.2774 20.584L21.7227 21.416Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Contract;
