import clsx from 'clsx';
import { MouseEvent, ReactNode, FunctionComponent, SVGProps, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import './ButtonLink.scss';
import Anchor, { AnchorProps } from 'components/anchor/Anchor';
import { LocationDescriptor } from 'history';
import parseLink from 'utils/parseLink';

interface Props extends Pick<AnchorProps, 'disableModal' | 'target' | 'rel' | 'id' | 'download'> {
	children?: ReactNode;
	iconLeft?: FunctionComponent<SVGProps<SVGSVGElement>>;
	iconRight?: FunctionComponent<SVGProps<SVGSVGElement>>;
	disabled?: boolean;
	underline?: boolean;
	size?: 'large' | 'normal';
	className?: string;
	onClick?: (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLSpanElement>) => void;
	hoverAnimation?: 'none' | 'bounce-left' | 'bounce-right' | 'bounce-up' | 'bounce-down';
	link?: LocationDescriptor;
	target?: string;
	contrast?: boolean;
	fontWeight?: 'normal';
	testid?: string;
}

const ButtonLink = forwardRef((
	{
		iconLeft: IconLeft,
		iconRight: IconRight,
		children,
		disabled,
		className,
		underline = false,
		size = 'normal',
		hoverAnimation = 'none',
		onClick,
		link,
		target,
		fontWeight,
		contrast,
		testid,
		...restProps
	}: Props,
	// Disabling this rule - not making effor to fix with move to design system in mind
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ref: any,
) => {
	const { isExternalLink, pathname } = parseLink(link);

	const classes = clsx('button-link', className, {
		'button-link--underline': underline,
		'button-link--contrast': contrast,
		[`button-link--size-${size}`]: size !== 'normal',
		[`button-link--hover-${hoverAnimation}`]: hoverAnimation !== 'none',
		[`button-link--font-weight-${fontWeight}`]: fontWeight !== undefined,
	});

	const content = (
		<>
			{IconLeft && <IconLeft className='button-link__icon button-link__icon--left' />}
			<span className='button-link__label'>{children}</span>
			{IconRight && <IconRight className='button-link__icon button-link__icon--right' />}
		</>
	);

	if (pathname !== undefined && !isExternalLink) {
		return (
			<Link
				ref={ref}
				to={pathname}
				target={target}
				className={classes}
				data-testid={testid}
				{...restProps}
			>
				{content}
			</Link>
		);
	}

	if (pathname !== undefined && isExternalLink) {
		return (
			<Anchor
				ref={ref}
				href={pathname}
				target={target}
				className={classes}
				data-testid={testid}
				{...restProps}
			>
				{content}
			</Anchor>
		);
	}

	return (
		<button
			ref={ref}
			className={classes}
			disabled={disabled}
			onClick={onClick}
			data-testid={testid}
			{...restProps}
		>
			{content}
		</button>
	);
});

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
