import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Documents = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.32007 31.66L27.1501 34.1L29.9301 13.86L30.3101 11.09L30.6801 8.34002L18.2001 6.63002L12.8501 5.90002L12.0901 11.42L9.32007 31.66Z'
				fill='#FFBFA5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 33H29V12.57V9.77V7H16.4H11V12.57V33Z'
				fill='#FF7842'
			/>
			<path
				d='M23 10.5H13V11.5H23V10.5Z'
				fill='#00215B'
			/>
			<path
				d='M22 13.5H13V14.5H22V13.5Z'
				fill='#00215B'
			/>
			<path
				d='M22 21.5H13V22.5H22V21.5Z'
				fill='#00215B'
			/>
			<path
				d='M19 24.5H13V25.5H19V24.5Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Documents;
