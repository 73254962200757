import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Nametag = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M35 11H5V29H35V11Z'
				fill='#F8BADD'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M34 11H5V29H35V11H34ZM34 28H6V12H34V28Z'
				fill='#F170B8'
			/>
			<path
				d='M30 17.5H10V18.5H30V17.5Z'
				fill='#00215B'
			/>
			<path
				d='M20 21.5H10V22.5H20V21.5Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Nametag;
