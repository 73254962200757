import { createContext, useCallback, useState, useContext } from 'react';

interface ModalContextProps {
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({ closeModal: () => {} });

type UseModalContext = () => [() => void];

export const useModalContext: UseModalContext = () => {
	const { closeModal } = useContext(ModalContext);
	return [closeModal];
};

type UseModal = (initialOpen: boolean) => [boolean, () => void, () => void];

export const useModal: UseModal = (initialOpen) => {
	const [open, setOpen] = useState<boolean>(initialOpen);
	const closeModal = useCallback(() => {
		setOpen(false);
	}, [setOpen]);
	const openModal = useCallback(() => {
		setOpen(true);
	}, [setOpen]);
	return [open, openModal, closeModal];
};
