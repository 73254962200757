import { Payment } from '../../../Shared.Frontend/generated-portal-woonstad/models/Payment';
import { PaymentPlanSummary } from '../../../Shared.Frontend/generated-portal-woonstad/models/PaymentPlanSummary';
import dayjs from 'dayjs';
import i18next from 'i18next';
import { paymentMethodMap } from 'hooks/store/useContract';

type SortPaymentList = (a: Payment & PaymentPlanSummary, b: PaymentPlanSummary & Payment) => number;

export const sortPaymentList: SortPaymentList = (a, b) => {
	const dateA = 'start' in a ? a.start : a.expirationDate;
	const dateB = 'start' in b ? b.start : b.expirationDate;
	return dayjs(dateB).unix() - dayjs(dateA).unix();
};

export const paymentMethodName = (paymentMethod?: keyof typeof paymentMethodMap) => {
	if (paymentMethod === undefined) {
		return i18next.t('Onbekend');
	}

	switch (true) {
		case paymentMethodMap[paymentMethod] === 'DIRECT_DEBIT':
			return i18next.t('Automatische incasso');
		case paymentMethodMap[paymentMethod] === 'GIRO_PAYMENT_SLIP':
			return i18next.t('Acceptgirokaart');
		case paymentMethodMap[paymentMethod] === 'UNKNOWN':
			return i18next.t('Onbekend');
		default:
			return i18next.t('Onbekend');
	}
};
