import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const ServiceContract = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M31.33 23.36C29.85 21.88 27.75 21.38 25.87 21.88L18.81 14.82L15.87 19.85L21.89 25.87C21.39 27.75 21.89 29.85 23.37 31.33C24.85 32.81 26.95 33.31 28.83 32.81L25.36 29.34L29.35 25.35L32.82 28.82C33.32 26.94 32.82 24.84 31.34 23.36H31.33Z'
				fill='#F8BADD'
			/>
			<path
				d='M20.15 33H15.49L9.42001 18.86L14.14 16.84L17.9 25.61L25.92 8.25L30.58 10.41L20.15 33Z'
				fill='#F170B8'
			/>
			<path
				d='M17.86 31.57H12.89L9.54001 23.81L10.46 23.41L13.55 30.57H17.86V31.57Z'
				fill='#00215B'
			/>
			<path
				d='M18.12 14.13C18.62 12.25 18.12 10.15 16.64 8.67C15.16 7.19 13.06 6.69 11.18 7.19L14.65 10.66L10.66 14.65L7.19001 11.18C6.69001 13.06 7.19001 15.16 8.67001 16.64C10.15 18.12 12.25 18.62 14.13 18.12L15.87 19.86L18.81 14.83L18.12 14.14V14.13Z'
				fill='#F8BADD'
			/>
		</svg>
	);
};

export default ServiceContract;
