import clsx from 'clsx';
import Box, { BoxProps } from 'components/box/Box';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import Typography from 'components/typography/Typography';
import { ReactNode, FunctionComponent, SVGProps, forwardRef, Ref } from 'react';
import styles from './BoxButton.module.scss';
import { LocationDescriptor } from 'history';

interface Props extends Pick<BoxProps, 'onClick' | 'id'> {
	link?: LocationDescriptor;
	icon: FunctionComponent<SVGProps<SVGSVGElement>>;
	className?: string;
	children: ReactNode;
	showArrow?: boolean;
	targetBlank?: boolean;
	rightIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
	size?: 'large',
	testid?: string,
	[x: string]: any; // rest props
}
const BoxButton = forwardRef((
	{
		link,
		onClick,
		id,
		icon: Icon,
		className,
		children,
		showArrow,
		rightIcon: RightIcon,
		targetBlank,
		size,
		testid,
		...restProps
	}: Props,
	ref: Ref<HTMLAnchorElement | HTMLDivElement | undefined>,
) => {
	const classes = clsx(styles.root, styles[`root--size-${size}`], className);
	const iconClasses = clsx(styles.icon, styles[`icon--size-${size}`]);
	return (
		<Box
			{...restProps}
			ref={ref}
			noPadding
			className={classes}
			link={link}
			onClick={onClick}
			id={id}
			targetBlank={targetBlank}
			testid={testid}
		>
			<Icon
				aria-hidden
				className={iconClasses}
			/>
			<Typography
				type='span'
				variant='button-label'
				fontWeight='500'
			>
				{children}
			</Typography>
			{showArrow && <ArrowForward aria-hidden />}
			{RightIcon && <RightIcon aria-hidden />}
		</Box>
	);
});

BoxButton.displayName = 'BoxButton';

export default BoxButton;
