import Box from 'components/box/Box';
import Layout from 'components/layout/Layout';
import Typography from 'components/typography/Typography';
import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import Input from 'components/input/Input';
import classes from './ChangeEmailMultiUser.module.scss';
import ArrowForward from 'assets/svg/arrow-forward.svg?react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Paths } from 'constants/routes';
import { useMutation } from '@tanstack/react-query';
import { validationSchema } from './validationSchema';
import { useConditionalRedirect } from 'hooks/useConditionalRedirect';
import { useAccount } from '@azure/msal-react';
import { AccountService } from '../../../../Shared.Frontend/generated-account/services/AccountService';
import { useCookies } from 'react-cookie';
import { createName } from 'utils/name';
import { useUserProfile } from 'hooks/store/useUserProfile';
import { useChooseAccount } from 'pages/choose-account/useChooseAccount';
import List from 'components/list/List';
import ListItem from 'components/list/ListItem';
import { getExternalLink } from 'utils/getExternalLink';

export interface FormValues {
	email: string,
	emailRepeat: string,
}

const ChangeEmailMultiUser = () => {
	const { t } = useTranslation();
	const account = useAccount();
	const [cookie] = useCookies(['customer-id']);
	const customerId = cookie['customer-id'];
	const { name, email, customer } = useUserProfile();
	const { userProfiles } = useChooseAccount();

	const {
		mutate,
		isLoading: isLoading,
		status,
		data,
	} = useMutation({ mutationFn: AccountService.postAccountApiAccountChangeEmailSingular });

	const {
		handleBlur,
		handleChange,
		handleSubmit,
		values,
		touched,
		errors,
	} = useFormik<FormValues>({
		validationSchema: validationSchema(account?.idTokenClaims?.email || ''),
		initialValues: {
			email: '',
			emailRepeat: '',
		},
		onSubmit: async (values) => {
			mutate({
				customerId: Number(customerId),
				newEmail: values.email,
			});
		},
	});

	useConditionalRedirect(userProfiles && userProfiles.length > 0, {
		failedRoute: Paths.NO_ACCESS,
	});

	useConditionalRedirect(status === 'error' ? false : status === 'success' ? true : undefined, {
		passedRoute: Paths.CHANGE_EMAIL_MULTI_USER_CONFIRMATION,
		emailAddress: values.email,
		accountExists: data?.accountWithEmailExists || false,
	});

	return (
		<Layout title='E-mailadres wijzigen'>
			<Box
				className={`${classes.boxWrapper} grid-1-lg`}
				noPadding
			>
				<form
					className='grid-1-xl'
					onSubmit={handleSubmit}
				>
					<Box
						variant='warning'
						noBackground
						noShadow
						className={`${classes.error} grid-1-md`}
					>
						<Typography
							variant='block-title'
							color='error'
						>
							<Trans>
								Let op: gedeeld e-mailadres
							</Trans>
						</Typography>
						<Typography
							variant='body-small'
							color='navy'
							dangerouslySetInnerHTML={t('Het e-mailadres {{email}} wordt gedeeld met andere gebruikers:', { email: email })}
						/>
						<div className={classes.list}>
							<List textColor='navy'>
								{userProfiles?.map((userProfile, index) => {
									const currentUser = customer?.id === userProfile?.customer?.id;
									return (
										<ListItem key={`${userProfile.initials}-${userProfile.lastname}-${index}`}>
											{currentUser ? (
												<b>
													{createName(userProfile.customer)}
													{customer?.id === userProfile?.customer?.id && ` ${t('(nu ingelogd)')}`}
												</b>
											) : (
												createName(userProfile.customer)
											)}
										</ListItem>
									);
								})}
							</List>
						</div>
						<Typography
							variant='body-small'
							color='navy'
						>
							<Trans>
								Je kunt alleen het e-mailadres wijzigen van de gebruiker die nu is ingelogd. De andere gebruikers blijven inloggen met het bestaande e-mailadres en wachtwoord.
							</Trans>
						</Typography>
					</Box>
					<div className={`${classes.fields} grid-1-xl`}>
						<div className={classes['single-field']}>
							<Typography
								variant='form-label'
								htmlFor='currentEmail'
							>
								<Trans>Huidig e-mailadres</Trans>
							</Typography>
							<Input
								id='currentEmail'
								name='currentEmail'
								type='text'
								value={account?.idTokenClaims?.email}
								disabled
							/>
						</div>
						<div className={classes['single-field']}>
							<Typography
								variant='form-label'
								htmlFor='email'
								dangerouslySetInnerHTML={t('Nieuw e-mailadres voor gebruiker {{name}}', { name })}
							/>
							<Input
								id='email'
								name='email'
								type='text'
								value={values.email}
								onChange={handleChange}
								onBlur={handleBlur}
								valid={touched.email === undefined ? undefined : errors.email === undefined}
								helperText={touched.email === undefined ? undefined : errors.email}
							/>
						</div>
						<div className={classes['single-field']}>
							<Typography
								variant='form-label'
								htmlFor='emailRepeat'
							>
								<Trans>Herhaal het e-mailadres ter controle</Trans>
							</Typography>
							<Input
								id='emailRepeat'
								name='emailRepeat'
								type='text'
								value={values.emailRepeat}
								onChange={handleChange}
								onBlur={handleBlur}
								valid={touched.emailRepeat === undefined ? undefined : errors.emailRepeat === undefined}
								helperText={touched.emailRepeat === undefined ? undefined : errors.emailRepeat}
							/>
						</div>
						<div>
							<ButtonPrimary
								disabled={Boolean(isLoading || Object.keys(errors).length > 0)}
								icon={ArrowForward}
								type='submit'
							>
								<Trans>Nieuw e-mailadres opslaan</Trans>
							</ButtonPrimary>
						</div>
					</div>
					{status === 'error' && (
						<Box
							noShadow
							variant='warning'
							noBackground
							className={classes.error}
						>
							<Typography
								variant='body-small'
								color='navy'
								dangerouslySetInnerHTML={
									t(
										'Er ging iets fout bij het opslaan van je e-mailadres.<br /><br />Kom je er niet uit? <a href={{ExternalLinkInfo}}>Neem dan contact op met de klantenservice.</a>',
										{ ExternalLinkInfo: getExternalLink('INFO') },
									)
								}
							/>
						</Box>
					)}
				</form>
			</Box>
		</Layout >
	);
};

export default ChangeEmailMultiUser;
