import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Calendar = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M32 10H8V32H32V10Z'
				fill='#60D15D'
			/>
			<path
				d='M32 13.89H8V32H32V13.89Z'
				fill='#D9F4D8'
			/>
			<path
				d='M12.5 8H11.5V12H12.5V8Z'
				fill='#00215B'
			/>
			<path
				d='M16.5 8H15.5V12H16.5V8Z'
				fill='#00215B'
			/>
			<path
				d='M20.5 8H19.5V12H20.5V8Z'
				fill='#00215B'
			/>
			<path
				d='M24.5 8H23.5V12H24.5V8Z'
				fill='#00215B'
			/>
			<path
				d='M28.5 8H27.5V12H28.5V8Z'
				fill='#00215B'
			/>
			<path
				d='M28 19.5C28.8284 19.5 29.5 18.8284 29.5 18C29.5 17.1716 28.8284 16.5 28 16.5C27.1716 16.5 26.5 17.1716 26.5 18C26.5 18.8284 27.1716 19.5 28 19.5Z'
				fill='#60D15D'
			/>
			<path
				d='M28 24.5C28.8284 24.5 29.5 23.8284 29.5 23C29.5 22.1716 28.8284 21.5 28 21.5C27.1716 21.5 26.5 22.1716 26.5 23C26.5 23.8284 27.1716 24.5 28 24.5Z'
				fill='#60D15D'
			/>
			<path
				d='M28 29.57C28.8284 29.57 29.5 28.8984 29.5 28.07C29.5 27.2416 28.8284 26.57 28 26.57C27.1716 26.57 26.5 27.2416 26.5 28.07C26.5 28.8984 27.1716 29.57 28 29.57Z'
				fill='#60D15D'
			/>
			<path
				d='M12 24.5C12.8284 24.5 13.5 23.8284 13.5 23C13.5 22.1716 12.8284 21.5 12 21.5C11.1716 21.5 10.5 22.1716 10.5 23C10.5 23.8284 11.1716 24.5 12 24.5Z'
				fill='#60D15D'
			/>
			<path
				d='M12 29.57C12.8284 29.57 13.5 28.8984 13.5 28.07C13.5 27.2416 12.8284 26.57 12 26.57C11.1716 26.57 10.5 27.2416 10.5 28.07C10.5 28.8984 11.1716 29.57 12 29.57Z'
				fill='#60D15D'
			/>
			<path
				d='M17.33 19.43C18.1584 19.43 18.83 18.7584 18.83 17.93C18.83 17.1016 18.1584 16.43 17.33 16.43C16.5016 16.43 15.83 17.1016 15.83 17.93C15.83 18.7584 16.5016 19.43 17.33 19.43Z'
				fill='#60D15D'
			/>
			<path
				d='M17.33 24.5C18.1584 24.5 18.83 23.8284 18.83 23C18.83 22.1716 18.1584 21.5 17.33 21.5C16.5016 21.5 15.83 22.1716 15.83 23C15.83 23.8284 16.5016 24.5 17.33 24.5Z'
				fill='#60D15D'
			/>
			<path
				d='M17.33 29.57C18.1584 29.57 18.83 28.8984 18.83 28.07C18.83 27.2416 18.1584 26.57 17.33 26.57C16.5016 26.57 15.83 27.2416 15.83 28.07C15.83 28.8984 16.5016 29.57 17.33 29.57Z'
				fill='#60D15D'
			/>
			<path
				d='M22.67 19.43C23.4984 19.43 24.17 18.7584 24.17 17.93C24.17 17.1016 23.4984 16.43 22.67 16.43C21.8416 16.43 21.17 17.1016 21.17 17.93C21.17 18.7584 21.8416 19.43 22.67 19.43Z'
				fill='#60D15D'
			/>
			<path
				d='M22.67 24.5C23.4984 24.5 24.17 23.8284 24.17 23C24.17 22.1716 23.4984 21.5 22.67 21.5C21.8416 21.5 21.17 22.1716 21.17 23C21.17 23.8284 21.8416 24.5 22.67 24.5Z'
				fill='#60D15D'
			/>
			<path
				d='M22.67 29.57C23.4984 29.57 24.17 28.8984 24.17 28.07C24.17 27.2416 23.4984 26.57 22.67 26.57C21.8416 26.57 21.17 27.2416 21.17 28.07C21.17 28.8984 21.8416 29.57 22.67 29.57Z'
				fill='#60D15D'
			/>
		</svg>
	);
};

export default Calendar;
