import clsx from 'clsx';
import { createContext, FunctionComponent, ReactNode } from 'react';
import './List.scss';
import { TypographyProps } from 'components/typography/Typography';

interface ContextProps {
	childSpacing: 'normal' | 'small' | 'large' | 'extra-large';
	lineHeight: 'normal' | 'large' | 'small';
	listType: 'bullet' | 'check';
	listIconSize: 'normal' | 'large';
	iconColor?: TypographyProps['color'],
	textColor: TypographyProps['color'],
	textSize: TypographyProps['variant']
}

interface Props {
	children: ReactNode;
	className?: string;
	textSize?: TypographyProps['variant'],
	childSpacing?: 'normal' | 'small' | 'large' | 'extra-large';
	lineHeight?: 'normal' | 'large' | 'small';
	listType?: 'bullet' | 'check';
	listIconSize?: 'normal' | 'large';
	iconColor?: TypographyProps['color'],
	textColor?: TypographyProps['color'],
	testid?: string
}

export const ListContext = createContext<ContextProps>({
	childSpacing: 'normal',
	listType: 'bullet',
	lineHeight: 'normal',
	listIconSize: 'normal',
	textColor: 'body-text',
	textSize: 'body-small',
});

const List: FunctionComponent<Props> = ({
	children,
	iconColor,
	className = '',
	textColor = 'body-text',
	childSpacing = 'normal',
	lineHeight = 'normal',
	listIconSize = 'normal',
	listType = 'bullet',
	textSize = 'body-small',
	testid,
}) => {
	const classes = clsx('list', className, {
		'list--list-type-check': listType === 'check',
		'list--large-icon': listIconSize === 'large',
	});

	return (
		<ul
			className={classes}
			data-testid={testid}
		>
			<ListContext.Provider value={{ childSpacing, listType, lineHeight, listIconSize, iconColor, textColor, textSize }}>
				{children}
			</ListContext.Provider>
		</ul>
	);
};

export default List;
