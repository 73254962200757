import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Business = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 16H31V32H9V16ZM11 8H29V13H11V8Z'
				fill='#F170B8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M13 24H18V32H13V24ZM22 24H27V29H22V24ZM32 16C32 17.66 30.66 19 29 19C27.34 19 26 17.66 26 16C26 17.66 24.66 19 23 19C21.34 19 20 17.66 20 16C20 17.66 18.66 19 17 19C15.34 19 14 17.66 14 16C14 17.66 12.66 19 11 19C9.34 19 8 17.66 8 16V13H32V16Z'
				fill='#F8BADD'
			/>
			<path
				d='M31 16.5H30V10.5H16V9.5H31V16.5Z'
				fill='#00215B'
			/>
			<path
				d='M13 20.5C11.07 20.5 9.5 18.93 9.5 17H10.5C10.5 18.38 11.62 19.5 13 19.5C14.38 19.5 15.5 18.38 15.5 17H16.5C16.5 18.93 14.93 20.5 13 20.5Z'
				fill='#00215B'
			/>
			<path
				d='M19 20.5C17.07 20.5 15.5 18.93 15.5 17H16.5C16.5 18.38 17.62 19.5 19 19.5C20.38 19.5 21.5 18.38 21.5 17H22.5C22.5 18.93 20.93 20.5 19 20.5Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Business;
