import ButtonLink from 'components/button-link/ButtonLink';
import LoadableLogo from 'components/logo/LoadableLogo';
import { Paths } from 'constants/routes';
import { useRef } from 'react';
import { Trans } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styles from './Header.module.scss';
import { useIsAuthenticated } from '@azure/msal-react';
import Hamburger from 'assets/svg/hamburger.svg?react';
import User from 'assets/svg/user.svg?react';
import Typography from 'components/typography/Typography';
import NavigationDialog from 'components/navigation-dialog/NavigationDialog';
import ButtonPrimary from 'components/button-primary/ButtonPrimary';
import safeWindow from 'utils/safeWindow';

export const Header = () => {
	const parentElement = useRef(null); // renders the radix modal in the right place
	const isAuthenticated = useIsAuthenticated();

	const location = useLocation();

	return (
		<header className={styles.rootv2}>
			<Link
				to={Paths.DASHBOARD}
				aria-label='MijnWoonstad'
			>
				<LoadableLogo />
			</Link>
			<div
				className={styles.contentRightNavigation}
				ref={parentElement}
			>
				{isAuthenticated ? (
					<>
						<NavigationDialog
							refContainer={parentElement.current}
							trigger={(
								<ButtonLink
									contrast
									className={styles.menuTrigger}
									iconRight={Hamburger}
									testid='menuButton'
								>
									<Typography
										variant='body'
										color='white'
									>
										<Trans>Menu</Trans>
									</Typography>
								</ButtonLink>
							)}
						/>
					</>
				) : (
					<ButtonPrimary
						icon={User}
						color='pink'
						link={`${safeWindow.location?.origin}?callback=${location.pathname}${location.search}`}
					>
						MijnWoonstad
					</ButtonPrimary>
				)}
			</div>
		</header>
	);
};
