import AzureContainer from 'components/azure-container/AzureContainer';
import styles from './LoadingPage.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import containerStyles from 'components/azure-container/AzureContainer.module.scss';
import Typography from 'components/typography/Typography';
import Box from 'components/box/Box';
import { safeEnv } from 'utils/safeEnv';
import TextInputSkeleton from 'components/skeleton/text-input-skeleton/TextInputSkeleton';
import TextSkeleton from 'components/skeleton/text-skeleton/TextSkeleton';

const LoadingPage = () => {
	const { t } = useTranslation();

	return (
		<AzureContainer
			title={t('Inloggen bij MijnWoonstad')}
			backLink={safeEnv('REACT_APP_WEBSITE')}
			backText='Woonstad Rotterdam'
		>
			<div className={containerStyles.twoBox}>
				<Box
					noPadding
					className={containerStyles.loginBox}
					variant='dark'
				>
					<div className='grid-1-lg'>
						<div>
							<div className={styles.label}>
								<Typography
									variant='form-label'
									siblingSpacing
								>
									<Trans>E-mailadres</Trans>
								</Typography>
							</div>
							<TextInputSkeleton />
						</div>

						<div>
							<div className={styles.label}>
								<Typography
									variant='form-label'
								>
									<Trans>Wachtwoord</Trans>
								</Typography>
							</div>
							<TextInputSkeleton />
						</div>

						<TextInputSkeleton />
						<TextInputSkeleton />
					</div>
				</Box>

				<div className={containerStyles.linkBox}>
					<div className='grid-1-lg'>
						<TextInputSkeleton />
						<div className='grid-1-sm'>
							<TextSkeleton />
						</div>
					</div>
				</div>
			</div>
		</AzureContainer>
	);
};

export default LoadingPage;
