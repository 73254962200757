export type RouteItem = {
  path: string;
  to: string;
};

const useAliasRouting = (routes: RouteItem[]) => {
	if (typeof window !== 'undefined') {
		const currentPath = window.location.pathname;
		const matchedRoute = routes.find((item) => item.path === currentPath);

		if (matchedRoute) {
			const redirectTo = matchedRoute.to;
			window.location.href = redirectTo;
		}
	}
};

export default useAliasRouting;
