import { LocationDescriptor } from 'history';

const parseLink = (link?: LocationDescriptor) => {
	if (!link) return {
		isExternalLink: false,
		pathname: undefined,
	};

	const isExternalLink = typeof link === 'string'
		? link?.startsWith('http')
		: link?.pathname?.startsWith('http') || link?.pathname?.startsWith('mailto') || link?.pathname?.startsWith('tel') || link?.pathname?.startsWith('sms');

	return {
		isExternalLink: isExternalLink ?? false,
		pathname: typeof link === 'string' ? link : link?.pathname,
	};
};

export default parseLink;
