import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Email = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M36 10H4V23H36V10Z'
				fill='#FFBFA5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4 10L20 20L36 10V30H4V10Z'
				fill='#FF7842'
			/>
			<path
				d='M8.26501 14.576L7.73502 15.424L16 20.5896L32.265 10.424L31.735 9.576L16 19.4104L8.26501 14.576Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Email;
