import { DASHBOARD_CONTRACT_TYPE_MAP } from 'pages/dashboard/CONSTS';
import { useContractToUse } from './useContractToUse';
import { ContractType } from '../../../Shared.Frontend/constants';
import { FormBlockType } from 'pages/dashboard/types';
import { useGetContractInfo } from './store/useContract';

export const useBackLink = () => {
	const currentPath = `/${window.location.pathname.split('/')[1]}`;

	const { contractToUse } = useContractToUse();
	const { contract } = useGetContractInfo(contractToUse?.id);

	const contractType = contract?.type as ContractType | undefined;

	if (!contract) return '/';
	if (!contractType) return '/';

	const findPathInDashboardConfig = DASHBOARD_CONTRACT_TYPE_MAP[contractType].tabs.find((tab) => {
		return [
			...tab.formBlocksCol1,
			...tab.formBlocksCol2,
		].find((block) => {
			if (block.type !== 'buttons' && block.type !== 'collection') {
				return false;
			}

			const innerButtonBlocks = block.type === 'collection'
				? block.inner
					.filter((innerBlock) => innerBlock.type === 'buttons')
					.reduce<Extract<FormBlockType, { type: 'buttons' }>['buttons']>(
						(acc, innerBlock) => {
							if (innerBlock.type !== 'buttons') return acc;
							return [...acc, ...innerBlock.buttons];
						}, [],
					)
				: [];

			const normalButtonBlocks = block.type === 'buttons'
				? block.buttons
				: [];

			return [...normalButtonBlocks, ...innerButtonBlocks].find((button) => {
				return typeof button.link === 'string'
					? button.link.startsWith(currentPath)
					: typeof button.link === 'function'
						? button.link(contract)?.startsWith(currentPath)
						: false;
			});
		});
	});

	return findPathInDashboardConfig?.path ?? '/';
};
