import { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from './useIsomorphicLayoutEffect';

type UseLockedBodyOutput = [boolean, (locked: boolean) => void]

export function useLockedBody(initialLocked = false): UseLockedBodyOutput {
	const [locked, setLocked] = useState(initialLocked);

	// Do the side effect before render
	useIsomorphicLayoutEffect(() => {
		if (!locked) {
			return undefined;
		}

		// Save initial body style
		const originalHtmlOverflow = document.documentElement.style.overflow;
		const originalBodyOverflow = document.body.style.overflow;

		// Lock body scroll
		document.documentElement.style.overflow = 'hidden';
		document.body.style.overflow = 'hidden';

		return () => {
			document.documentElement.style.overflow = originalHtmlOverflow;
			document.body.style.overflow = originalBodyOverflow;
		};
	}, [locked]);

	// Update state if initialValue changes
	useEffect(() => {
		if (locked !== initialLocked) {
			setLocked(initialLocked);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialLocked]);

	return [locked, setLocked];
}

export default useLockedBody;
