import ButtonLink from 'components/button-link/ButtonLink';
import { CSSTransition } from 'react-transition-group';
import { Paths } from 'constants/routes';
import { useLogoutAction } from 'hooks/useLogout';
import useMedia from 'hooks/useMedia';
import { FunctionComponent, useState, ReactNode, useEffect, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styles from './NavigationDialog.module.scss';
import * as Dialog from '@radix-ui/react-dialog';
import Close from 'assets/svg/close.svg?react';
import LogoLight from 'assets/svg/logo-light.svg?react';
import Box from 'components/box/Box';
import Typography from 'components/typography/Typography';
import BoxButton from 'components/box-button/BoxButton';
import InfoBlue from 'assets/svg/rich/info-blue.svg?react';
import { useContracts } from 'hooks/store/useContracts';
import { contractTypeIconSVG, contractTypeTitle } from 'constants/contract';
import { useHistory } from 'react-router-dom';
import { useContractToUse } from 'hooks/useContractToUse';
import Email from 'pages/dashboard/icons/account/Email';
import Details from 'pages/dashboard/icons/account/Details';
import Logout from 'pages/dashboard/icons/account/Logout';
import Password from 'pages/dashboard/icons/account/Password';
import useLockedBody from 'hooks/useLockedBody';
import Contract from 'pages/dashboard/icons/account/Contract';
import { safeEnv } from 'utils/safeEnv';
import { useAccount } from '@azure/msal-react';
import { parseMsalUrl } from 'auth/config';
import { useUserProfile } from 'hooks/store/useUserProfile';
import { getExternalLink } from 'utils/getExternalLink';
import { useQueryClient } from '@tanstack/react-query';

interface Props {
	trigger: ReactNode,
	refContainer?: HTMLElement | null;
}

const NavigationDialog: FunctionComponent<Props> = ({ trigger, refContainer }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [open, setOpen] = useState(false);
	const logoutClick = useLogoutAction();
	const [mobile] = useMedia('(max-width: 500px)');
	const { contracts } = useContracts();
	const history = useHistory();
	const { contractToUse, setContractInStorage } = useContractToUse();
	const { name, email } = useUserProfile();
	const [, setLocked] = useLockedBody(false);

	const account = useAccount();
	const multiUser = (account?.idTokenClaims?.extension_CustomerIds?.split(',')?.length ?? 0) > 1;

	const handleOpenChange = useCallback((isOpen: boolean) => {
		setOpen(isOpen);
		setLocked(isOpen);
	}, [setLocked]);

	useEffect(() => {
		handleOpenChange(false);
		setLocked(false);
	}, [history.location, handleOpenChange, setLocked]);

	const handleSelectContract = (id: number | undefined) => {
		handleOpenChange(false);
		setLocked(false);
		setContractInStorage(id?.toString());
		queryClient.invalidateQueries();
	};

	return (
		<>
			<CSSTransition
				in={open}
				timeout={200}
				classNames={{
					enter: styles.overlayEnter,
					enterActive: styles.overlayEnterActive,
					exit: styles.overlayExit,
					exitActive: styles.overlayExitActive,
				}}
				unmountOnExit
			>
				<div
					className={styles.overlay}
				/>
			</CSSTransition>
			<Dialog.Root
				open={open}
				onOpenChange={handleOpenChange}
				modal={mobile}
			>
				<Dialog.Trigger asChild>
					{trigger}
				</Dialog.Trigger>
				<Dialog.Portal container={!mobile ? refContainer : undefined}>
					<Dialog.Overlay
						className={styles.overlay}
						forceMount={true}
						asChild
					/>
					<Dialog.Content className={styles.dialog}>
						<Box
							className={styles.container}
							variant={!mobile ? 'soft-dark' : 'light'}
							noPadding
						>
							<div className='grid-1-md pb-6'>
								<div className={styles.header}>
									<LogoLight className={styles.logo} />
									<Dialog.Close asChild>
										<ButtonLink
											className={styles.menuTrigger}
											iconRight={Close}
										>
											<Typography
												variant='body'
												color='blue'
											>
												<Trans>Sluiten</Trans>
											</Typography>
										</ButtonLink>
									</Dialog.Close>
								</div>
								{contracts && contracts.length > 0 && (
									<div className='grid-1-md p-6'>
										<Typography
											variant='body-title'
											color='navy'
										>
											<Trans>Contracten</Trans>
										</Typography>
										{contracts.map((contract) => (
											<BoxButton
												key={contract?.id}
												showArrow={false}
												icon={contractTypeIconSVG(contract?.type)}
												onClick={() => handleSelectContract(contract?.id)}
												className={contractToUse?.id === contract?.id && contracts.length > 1 ? styles.activeContract : undefined}
												noBackground
												testid='contractButton'
											>
												<Typography
													variant='uppercase-tag'
													color='tags'
													fontWeight='500'
												>
													{contractTypeTitle(contract?.type)}
												</Typography>
												<Typography
													variant='body-small'
													color='navy'
													fontWeight='500'
												>
													{contract?.address}
													<br />
													{contract?.zipCode}
													{' '} {contract?.city}
												</Typography>
											</BoxButton>
										))}
									</div>
								)}
								{/* <div className='grid-1-md p-6'>
									<Typography
										variant='body-title'
										color='navy'
									>
										<Trans>Wooncommissie</Trans>
									</Typography>
									<BoxButton
										showArrow={false}
										icon={User}
										link={Paths.USER_PROFILE}
									>
										<Typography
											variant='body-small'
											color='navy'
											fontWeight='500'
										>
											<Trans>KoBra</Trans>
										</Typography>
									</BoxButton>
								</div> */}

								<div className='grid-1-md p-6'>
									<div className='grid-1-sm'>
										<Typography
											variant='body-title'
											color='navy'
										>
											<Trans>Account</Trans>
										</Typography>
										{name ? (
											<Typography
												variant='body'
												dangerouslySetInnerHTML={multiUser
													? t(`Ingelogd als {{name}} met e-mailadres {{email}} <a href="${Paths.CHOOSE_ACCOUNT}" data-testid="changeUser">(wissel van gebruiker)</a>`, { name, email })
													: t('Ingelogd als {{name}} met e-mailadres {{email}}', { name, email })
												}
												data-testid='accountInfo'
											/>
										) : (
											<Typography
												variant='body'
												dangerouslySetInnerHTML={multiUser
													? t(`Ingelogd met e-mailadres {{email}} <a href="${Paths.CHOOSE_ACCOUNT}">(wissel van gebruiker)</a>`, { email })
													: t('Ingelogd met e-mailadres {{email}}', { email })
												}
											/>
										)}
									</div>
									<BoxButton
										showArrow={false}
										icon={Details}
										link={Paths.USER_PROFILE}
										testid='mijnGegevensLink'
									>
										<Typography
											variant='body-small'
											color='navy'
											fontWeight='500'
										>
											<Trans>Mijn gegevens</Trans>
										</Typography>
									</BoxButton>
									{!contracts && (
										<BoxButton
											showArrow={false}
											icon={Contract}
											link={Paths.HUURCONTRACT_KOPPELEN}
											testid='huurcontractKoppelenLink'
										>
											<Typography
												variant='body-small'
												color='navy'
												fontWeight='500'
											>
												<Trans>Huurcontract koppelen</Trans>
											</Typography>
										</BoxButton>
									)}
									<BoxButton
										showArrow={false}
										icon={Password}
										link={parseMsalUrl(safeEnv('B2C_CHANGE_PASSWORD') ?? '')}
										testid='wachtwoordWijzigenLink'
									>
										<Typography
											variant='body-small'
											color='navy'
											fontWeight='500'
										>
											<Trans>Wachtwoord wijzigen</Trans>
										</Typography>
									</BoxButton>
									<BoxButton
										showArrow={false}
										icon={Email}
										link={Paths.MAIL_PREFERENCES}
										testid='emailvoorkeurenLink'
									>
										<Typography
											variant='body-small'
											color='navy'
											fontWeight='500'
										>
											<Trans>E-mailvoorkeuren</Trans>
										</Typography>
									</BoxButton>
									<BoxButton
										showArrow={false}
										icon={Logout}
										onClick={logoutClick}
										testid='uitloggenButton'
									>
										<Typography
											variant='body-small'
											color='navy'
											fontWeight='500'
										>
											<Trans>Uitloggen</Trans>
										</Typography>
									</BoxButton>
								</div>
								<div className='grid-1-md p-6'>
									<Typography
										variant='body-title'
										color='navy'
									>
										<Trans>Klantenservice</Trans>
									</Typography>
									<BoxButton
										showArrow={false}
										icon={InfoBlue}
										link={getExternalLink('CONTACT')}
										targetBlank
										testid='contactLink'
									>
										<Typography
											variant='body-small'
											color='navy'
											fontWeight='500'
										>
											<Trans>Neem contact met ons op</Trans>
										</Typography>
									</BoxButton>
								</div>
							</div>
						</Box>
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog.Root>
		</>
	);
};

export default NavigationDialog;
