import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Logout = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M29.5 7H13.5V33H29.5V7Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.5 7.00001L26.29 4.21001V35.79L10.5 33V7.00001Z'
				fill='#FFBFA5'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.16 18.98L13.02 17.93V8.62L12.16 8.75V18.98Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.72 9.29L16.66 8.08L12.16 8.75V9.77L15.72 9.29Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M12.16 31.25L13.02 30.34V21.03L12.16 21.02V31.25Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15.72 22.14L16.66 21.08L12.16 21.02V22.05L15.72 22.14Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.62 18.89L19.63 17.75V7.64L18.62 7.79V18.89Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.83 8.33001L23.95 6.99001L18.62 7.79001V8.90001L22.83 8.33001Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.62 32.21L19.63 31.24V21.12L18.62 21.11V32.21Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.83 22.34L23.95 21.18L18.62 21.11V22.22L22.83 22.34Z'
				fill='#FF7842'
			/>
			<path
				d='M20 20.05L25 20.14'
				stroke='#00215B'
				strokeMiterlimit='10'
			/>
			<path
				d='M24.52 18.65V22.2'
				stroke='#00215B'
				strokeMiterlimit='10'
			/>
		</svg>
	);
};

export default Logout;
