import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localLng } from 'constants/locale';

import nlNL from './nl-NL.json';

const lng = localLng();

i18next
	.use(initReactI18next)
	.init({
		resources: {
			// No English translations (yet)
			'nl-NL': { translation: nlNL },
			// 'en-US': { translation: require('../translations/en-US.json') }
		},
		fallbackLng: lng,
		lng: 'nl-NL',
		load: 'currentOnly',
		debug: false,
		nsSeparator: false,
		keySeparator: false,

		interpolation: {
			escapeValue: false,
		},
		react: {
			transEmptyNodeValue: '', // what to return for empty Trans
			transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
			transKeepBasicHtmlNodesFor: [], // don't convert to <1></1> if simple react elements
			useSuspense: false,
		},
	});

export default i18next;
