import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import safeWindow from 'utils/safeWindow';

/**
 * Universal Analytics has been moved to GA4, use 'trackEvent' instead
 * @deprecated This method should not be used.
 */
export const useGoogleTagManagerTrackEvent = (event?: string, category?: string, action?: string, label?: string) => {
	const { track } = useAnalytics();
	const trackEvent = useCallback(
		(event: string, eventCategory: string, eventAction: string, eventLabel: string) => {
			if (track) {
				track(event, { event, eventCategory, eventAction, eventLabel });
				if (import.meta.env.PROD === false) {
					console.log(`Event: %c${event}, ${eventCategory}, ${eventAction}, ${eventLabel}`, 'color: #aaa');
				}
			}
		},
		[track],
	);
	useEffect(() => {
		if (event !== undefined && category !== undefined && action !== undefined && label !== undefined) {
			trackEvent(event, category, action, label);
		}
	}, [trackEvent, event, category, action, label]);
	return trackEvent;
};

export const EVENT_NAME = {
	VIRTUAL_PAGEVIEW: 'virtualPageview',
	FORM_STEP: 'form_step',
	CONTENT_INTERACTION: 'content_interaction',
} as const;

export const FORM_NAME = {
	AddRoommate: 'bijschrijven_nieuwe_inwoner',
	RentalReferenceLetter: 'aanvragen_verhuurdersverklaring',
	RentReductionRequest: 'aanvragen_huurverlaging',
	ChangeDirectDebitDate: 'wijzigen_incassodatum',
	ServiceSubscription: 'aanzetten_serviceabonnement',
	CancelContract: 'opzeggen_huur',
	RequestKey: 'aanvragen_sleutel',
	RequestNametag: 'aanvragen_fysiek_naamplaatje',
	RentIncreaseObjection: 'bezwaar_huurverhoging',
	AdvancementAdjustment: 'aanpassen_voorschot',
	KlussenInJeWoning: 'aanvragen_zav',
	KlussenInJeWoningPrelogin: 'aanvragen_zav_prelogin',
	WoonfraudeMelden: 'woonfraude_melden',
} as const;

type Event = {
	// [key: string]: string | number | null | undefined;
	event: typeof EVENT_NAME[keyof typeof EVENT_NAME];
	pagePath?: string;
	contracttype?: string;
	form_name?: typeof FORM_NAME[keyof typeof FORM_NAME];
	form_step_name?: string;
	form_step_number?: number;
	form_variant?: string | number | null;
	form_value?: string;
	content_group?: string;
	content_type?: string;
	interaction_type?: string;
}

export const trackEvent = (event: Event) => {
	if (import.meta.env.PROD) {
		safeWindow.dataLayer?.push(event);
	} else {
		console.groupCollapsed(`Event: ${event.event}`);
		console.table(event);
		console.groupEnd();
	}
};

export const useGoogleTagManagerVirtualPageView = () => {
	const { track } = useAnalytics();
	const { pathname } = useLocation();

	useEffect(() => {
		trackEvent({
			event: EVENT_NAME.VIRTUAL_PAGEVIEW,
			pagePath: pathname,
		});
	}, [track, pathname]);
};
