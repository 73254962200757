import clsx from 'clsx';
import { ComponentPropsWithoutRef, ElementType, forwardRef, ForwardRefRenderFunction } from 'react';
import './HelperText.scss';

interface Props extends ComponentPropsWithoutRef<ElementType> {
  fontSize?: 'default' | 'small';
  className?: string;
  noPadding?: boolean;
  color?: 'error' | 'success';
  id?: string
}

const HelperText: ForwardRefRenderFunction<HTMLSpanElement, Props> = (
	{ children, className, color, id, fontSize = 'default', noPadding = false },
	ref,
) => {
	const classes = clsx('helper-text', className, {
		'helper-text--no-padding': noPadding,
		[`helper-text--font-size-${fontSize}`]: fontSize !== undefined && fontSize !== 'default',
		[`helper-text--font-color-${color}`]: color !== undefined,
	});
	return (
		<span
			ref={ref}
			className={classes}
			data-helper-text={id}
		>
			{children}
		</span>
	);
};

export default forwardRef(HelperText);
