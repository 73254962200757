import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Key = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M13.05 25.28C12.07 25.28 11.08 25.07 10.16 24.64L10.58 23.73C12 24.39 13.59 24.46 15.06 23.92C16.53 23.38 17.7 22.31 18.36 20.89L19.27 21.31C18.5 22.97 17.13 24.23 15.41 24.86C14.64 25.14 13.85 25.28 13.05 25.28Z'
				fill='#00215B'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.37 17.7L29.97 30.82L23.59 35L20.8 30.75L22.93 29.36L17.12 20.49C14.07 21.24 10.74 20.04 8.92001 17.26C6.61001 13.74 7.59001 9.01 11.12 6.7C14.64 4.39 19.37 5.37 21.68 8.9C23.5 11.68 23.27 15.2 21.37 17.7ZM13.91 10.95C12.74 11.72 12.41 13.3 13.18 14.47C13.95 15.64 15.53 15.97 16.7 15.2C17.87 14.43 18.2 12.85 17.43 11.68C16.66 10.51 15.08 10.18 13.91 10.95Z'
				fill='#F8BADD'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M21.89 15.91L33 26.99L27.61 32.39L24.01 28.8L25.81 27L18.3 19.51C15.47 20.87 11.97 20.37 9.62001 18.03C6.64001 15.05 6.63001 10.23 9.61001 7.24C12.59 4.26 17.41 4.25 20.4 7.23C22.75 9.58 23.25 13.07 21.9 15.91H21.89ZM13.2 10.83C12.21 11.82 12.21 13.43 13.2 14.43C14.19 15.43 15.8 15.42 16.8 14.43C17.79 13.44 17.79 11.83 16.8 10.83C15.81 9.83 14.2 9.84 13.2 10.83Z'
				fill='#F170B8'
			/>
			<path
				d='M10.17 24.64C8.51 23.87 7.25 22.49 6.62 20.77C5.99 19.05 6.07 17.19 6.84 15.53C7.61 13.87 8.98 12.61 10.7 11.98C12.42 11.35 14.28 11.43 15.94 12.2L15.52 13.11C14.1 12.45 12.51 12.38 11.04 12.92C9.57 13.46 8.4 14.53 7.74 15.95C7.08 17.37 7.01 18.96 7.55 20.43C8.09 21.9 9.16 23.07 10.58 23.73L10.16 24.64H10.17Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Key;
