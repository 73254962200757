
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Builds on useLocation to parse the query string
function useQueryParams() {
	const { search } = useLocation();

	return useMemo(() => new URLSearchParams(search), [search]);
}

export default useQueryParams;
