import { FunctionComponent } from 'react';
interface GradientProps {
  id: string;
  color1: string;
  color2: string;
}
const SkeletonGradient: FunctionComponent<GradientProps> = ({ id, color1, color2 }) => {
	return (
		<linearGradient
			id={id}
			x1='-400%'
			x2='0%'
			y1='0%'
		>
			<animate
				attributeName='x1'
				dur='2s'
				from='-400%'
				to='0%'
				repeatCount='indefinite'
			/>
			<animate
				attributeName='x2'
				dur='2s'
				from='0%'
				to='450%'
				repeatCount='indefinite'
			/>
			<stop
				offset='0'
				style={{ stopColor: color1, stopOpacity: 1 }}
			/>
			<stop
				offset='0.3'
				style={{ stopColor: color1, stopOpacity: 1 }}
			/>
			<stop
				offset='0.4'
				style={{ stopColor: color2, stopOpacity: 1 }}
			/>
			<stop
				offset='0.6'
				style={{ stopColor: color2, stopOpacity: 1 }}
			/>
			<stop
				offset='0.7'
				style={{ stopColor: color1, stopOpacity: 1 }}
			/>
			<stop
				offset='1'
				style={{ stopColor: color1, stopOpacity: 1 }}
			/>
		</linearGradient>
	);
};

export default SkeletonGradient;
