import { useAccount, useMsal } from '@azure/msal-react';
import { useCallback } from 'react';

export const useLogoutAction = () => {
	const account = useAccount();
	const { instance } = useMsal();

	const logout = useCallback(() => {
		instance.logout({
			idTokenHint: account?.idToken,
		});
	}, [account?.idToken, instance]);

	return logout;
};
