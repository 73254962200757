import { ContractType } from '../../../Shared.Frontend/constants/index';
import i18next from 'i18next';
import Business from 'pages/dashboard/icons/main-flows/Business';
import House from 'pages/dashboard/icons/main-flows/House';
import Parking from 'pages/dashboard/icons/main-flows/Parking';
import { ReactNode } from 'react';

export const contractTypeTitle = (type: ContractType | string | undefined) => {
	switch (type) {
		case ContractType.HOME:
			return i18next.t('Woning');
		case ContractType.BUSINESS:
			return i18next.t('Bedrijfsruimte');
		case ContractType.PARKING:
			return i18next.t('Parkeerplaats');
		default:
			return '';
	}
};

type ContractTypeIcon = (type?: string, className?: string) => ReactNode;

export const contractTypeIcon: ContractTypeIcon = (type, className) => {
	switch (type) {
		case ContractType.HOME:
			return <House className={className} />;
		case ContractType.PARKING:
			return <Parking className={className} />;
		case ContractType.BUSINESS:
			return <Business className={className} />;
		default:
			return <span />;
	}
};

export const contractTypeIconSVG = (type?: string) => {
	switch (type) {
		case ContractType.HOME:
			return House;
		case ContractType.PARKING:
			return Parking;
		case ContractType.BUSINESS:
			return Business;
		default:
			return null;
	}
};
