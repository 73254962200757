import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const ExclamationMark = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M31 8V32H23L20 36L17 32H9V8H31Z'
				fill='#F170B8'
			/>
			<path
				d='M22.01 36L17.77 31H10V25.75H11V30H18.23L22.78 35.35L22.01 36Z'
				fill='#00215B'
			/>
			<path
				d='M20 28C19.47 28 19.02 27.82 18.66 27.46C18.3 27.1 18.12 26.66 18.12 26.15C18.12 25.64 18.31 25.17 18.68 24.8C19.05 24.43 19.49 24.24 20.01 24.24C20.53 24.24 20.97 24.43 21.34 24.8C21.71 25.17 21.9 25.62 21.9 26.15C21.9 26.68 21.72 27.1 21.36 27.46C21 27.82 20.55 28 20.02 28H20ZM18.14 12H21.86L21.22 22.38H18.77L18.13 12H18.14Z'
				fill='white'
			/>
		</svg>
	);
};

export default ExclamationMark;
