const getDomain = (url: string, includeSubdomains: boolean = false) => {
  let urlWithoutProtocolAndWWW = url.replace(/(https?:\/\/)?(www.)?/i, '');

  if (!includeSubdomains) {
    const urlParts = urlWithoutProtocolAndWWW.split('.');
    let urlWithoutSubDomains = urlParts.slice(urlParts.length - 2).join('.');

    if (urlWithoutSubDomains.includes('/')) {
      urlWithoutSubDomains = urlWithoutSubDomains.split('/')[0];
    }

    if (urlWithoutSubDomains.includes(':')) {
      urlWithoutSubDomains = urlWithoutSubDomains.split(':')[0];
    }

    return urlWithoutSubDomains;
  }

  if (urlWithoutProtocolAndWWW.includes('/')) {
    urlWithoutProtocolAndWWW = urlWithoutProtocolAndWWW.split('/')[0];
  }

  if (urlWithoutProtocolAndWWW.includes(':')) {
    urlWithoutProtocolAndWWW = urlWithoutProtocolAndWWW.split(':')[0];
  }

  return urlWithoutProtocolAndWWW;
}

export default getDomain;

/*
const test1 = getDomain('https://www.woonstadrotterdam.nl');
if (test1 === 'woonstadrotterdam.nl') console.log('test1 is equal to woonstadrotterdam.nl');
else console.log('test1 is not equal to woonstadrotterdam.nl');

const test2 = getDomain('https://www.woonstadrotterdam.nl', true);
if (test2 === 'woonstadrotterdam.nl') console.log('test2 is equal to woonstadrotterdam.nl');
else console.log('test2 is not equal to woonstadrotterdam.nl');

const test3 = getDomain('https://www.woonstadrotterdam.nl', false);
if (test3 === 'woonstadrotterdam.nl') console.log('test3 is equal to woonstadrotterdam.nl');
else console.log('test3 is not equal to woonstadrotterdam.nl');

const test4 = getDomain('https://www.woonstadrotterdam.nl/over-woonstad');
if (test4 === 'woonstadrotterdam.nl') console.log('test4 is equal to woonstadrotterdam.nl');
else console.log('test4 is not equal to woonstadrotterdam.nl');

const test5 = getDomain('https://mijn.woonstadrotterdam.nl/');
if (test5 === 'woonstadrotterdam.nl') console.log('test5 is equal to woonstadrotterdam.nl');
else console.log('test5 is not equal to woonstadrotterdam.nl');

const test6 = getDomain('https://mijn.woonstadrotterdam.nl/', true);
if (test6 === 'mijn.woonstadrotterdam.nl') console.log('test6 is equal to mijn.woonstadrotterdam.nl');
else console.log('test6 is not equal to mijn.woonstadrotterdam.nl');
*/
