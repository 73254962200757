import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Paint = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M25.2585 25.8963L13.9448 14.5826L5.83425 22.6931L17.148 34.0068L25.2585 25.8963Z'
				fill='#FBDCEE'
			/>
			<path
				d='M26.06 20.32L28.01 22.27C28.25 22.51 28.25 22.91 28.01 23.15L25.27 25.89L13.96 14.58L16.7 11.84C16.94 11.6 17.34 11.6 17.58 11.84L19.53 13.79C19.81 14.07 20.19 14.23 20.58 14.23C22.17 14.23 23.65 13.41 24.5 12.06C25.16 11 25.69 9.85 26.08 8.7C26.36 7.87 26.92 7.11 27.78 6.57C29.03 5.79 30.68 5.78 31.93 6.57C34.22 8 34.47 11.04 32.69 12.83C32.23 13.29 31.68 13.62 31.09 13.8C29.98 14.15 28.77 14.73 27.68 15.43C26.38 16.27 25.61 17.73 25.61 19.28C25.61 19.68 25.77 20.06 26.05 20.33L26.06 20.32ZM29.16 10.69C29.55 11.08 30.18 11.08 30.57 10.69C30.96 10.3 30.96 9.67 30.57 9.28C30.18 8.89 29.55 8.89 29.16 9.28C28.77 9.67 28.77 10.3 29.16 10.69Z'
				fill='#F170B8'
			/>
			<path
				d='M8.65001 19.88L5.82001 22.71L17.13 34.02L19.96 31.19C19.96 28.36 17.13 31.19 15.72 29.78C14.31 28.37 18.55 26.95 17.13 25.54C15.71 24.13 14.3 28.37 12.89 26.95C11.48 25.53 14.3 25.54 12.89 24.12C11.48 22.7 11.48 25.53 10.06 24.12C8.64001 22.71 12.89 19.88 8.65001 19.88Z'
				fill='#F170B8'
			/>
			<path
				d='M24.25 25.56L17.9 19.21L18.61 18.5L24.26 24.15L26.56 21.85C27.18 21.23 27.18 20.21 26.56 19.58L27.27 18.87C28.28 19.88 28.28 21.54 27.27 22.55L24.26 25.56H24.25Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Paint;
