import { Locale } from 'constants/locale';
import { createContext, useContext } from 'react';
interface LanguageSelectorContextProps {
  language: string;
  setLanguage: (language: string) => void;
}
export const LanguageSelectorContext = createContext<LanguageSelectorContextProps>({
	language: Locale.NL,
	setLanguage: () => {},
});

type UseLanguageSelector = () => [string, (language: string) => void];

export const useLanguageSelector: UseLanguageSelector = () => {
	const { language, setLanguage } = useContext(LanguageSelectorContext);

	return [language, setLanguage];
};
