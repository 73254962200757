import useTransitionHeight from 'hooks/useTransitionHeight';
import { FunctionComponent, ReactNode } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './AuthPageTransition.scss';

interface Props {
  children: ReactNode;
}

const AuthPageTransition: FunctionComponent<Props> = ({ children }) => {
	const location = useLocation();
	const [height, onSetHeight, onResetHeight] = useTransitionHeight();

	return (
		<div
			className='auth-page-transition'
			style={{ height }}
		>
			<TransitionGroup component={null}>
				<CSSTransition
					timeout={{ enter: 400, exit: 150 }}
					key={location.pathname}
					classNames='auth-page-transition__route-'
					onEntering={onSetHeight}
					onEntered={onResetHeight}
					onExit={onSetHeight}
				>
					<div className='auth-page-transition__route-container'>
						<Switch location={location}>{children}</Switch>
					</div>
				</CSSTransition>
			</TransitionGroup>
		</div>
	);
};

export default AuthPageTransition;
