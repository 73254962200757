import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Parking = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M29 7H11V33H29V7Z'
				fill='#F170B8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18 13H20C22.76 13 25 15.24 25 18C25 20.76 22.76 23 20 23H18V28H15V13H18ZM18 16V20H20C21.1 20 22 19.1 22 18C22 16.9 21.1 16 20 16H18Z'
				fill='white'
			/>
			<path
				d='M31 32H21V31H30V18H31V32Z'
				fill='#00215B'
			/>
			<path
				d='M14 10H13V5H17V6H14V10Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default Parking;
