import { NavigationClient } from '@azure/msal-browser';
import { useHistory } from 'react-router-dom';

/**
 * This is an example for overriding the default function MSAL uses to navigate to other urls in your webpage
 */
export class CustomNavigationClient extends NavigationClient {
	constructor(navigate: ReturnType<typeof useHistory>) {
		super();
		this.navigate = navigate;
	}

	navigate: ReturnType<typeof useHistory> | null = null;

	/**
		 * Navigates to other pages within the same web application
		 * You can use the useNavigate hook provided by react-router-dom to take advantage of client-side routing
		 * @param url
		 * @param options
		 */
	async navigateInternal(url: string, options: { noHistory?: boolean }) {
		const relativePath = url.replace(window.location.origin, '');
		if (options.noHistory) {
			this.navigate?.replace(relativePath, { replace: true });
		} else {
			this.navigate?.replace(relativePath);
		}

		return false;
	}
}
