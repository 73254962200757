export const Paths = {
	DASHBOARD: '/',
	DASHBOARD_WOONRUIMTE: '/in-en-om-mijn-woonruimte',
	DASHBOARD_PARKEERPLAATS: '/in-en-om-mijn-parkeerplaats',
	DASHBOARD_BUSINESS: '/in-en-om-mijn-bedrijfsruimte',
	DASHBOARD_HUISHOUDEN: '/huishouden',
	DASHBOARD_HUUR_BETALINGEN: '/huur-betalingen',
	DASHBOARD_FACTUREN: '/facturen',
	DASHBOARD_DOCUMENTEN: '/documenten',

	LOGIN_B2C: '/login-b2c',
	LOGIN_SIMPLE_B2C: '/login-simple-b2c',
	FORGOT_PASSWORD_B2C: '/forgot-password-b2c',
	CHANGE_PASSWORD_B2C: '/change-password-b2c',
	PROFILE_UPDATE_B2C: '/profile-update-b2c',
	LOGGED_OUT: '/logged-out',
	LOGIN_FAILED: '/login-failed',
	REGISTER_B2C: '/register-b2c',
	INVITATION_B2C: '/invitation-b2c',

	CHOOSE_ACCOUNT: '/kies-gebruiker',

	HOME_VALUATION_POINTS: '/woning-waarderingspunten',

	SERCIVE_SUBSCRIPTION: '/service-abonnement',
	SERCIVE_SUBSCRIPTION_CONFIRMATION: '/service-abonnement/succes',

	CHANGE_DIRECT_DEBIT_DATE: '/flexibele-incasso',
	CHANGE_DIRECT_DEBIT_DATE_CONFIRMATION: '/flexibele-incasso/success',
	CHANGE_DIRECT_DEBIT_DATE_ERROR: '/flexibele-incasso/error',

	PAYMENT_RETURN: '/betaling',

	NOTIFICATIONS: '/meldingen',
	USER_PROFILE: '/mijn-gegevens',

	CANCEL_CONTRACT: '/huur-opzeggen',
	CANCEL_CONTRACT_CONFIRMATION: '/huur-opzeggen/succes',
	CANCEL_CONTRACT_ERROR: '/huur-opzeggen/mislukt',

	ADD_ROOMMATE: '/medebewoner-aanmelden',
	ADD_ROOMMATE_CONFIRMATION: '/medebewoner-aanmelden/succes',
	ADD_ROOMMATE_ERROR: '/medebewoner-aanmelden/mislukt',

	PAYMENT_PLAN: '/betalingsregeling-treffen',
	PAYMENT_PLAN_SUCCESS: '/betalingsregeling-treffen/succes',
	PAYMENT_PLAN_ERROR: '/betalingsregeling-treffen/mislukt',

	REQUEST_KEY: '/sleutel-aanvragen',
	REQUEST_KEY_PAYMENT_RETURN: '/sleutel-aanvragen/betaling',

	CHANGE_MAINTENANCE_APPOINTMENT: '/afspraak-reparatieverzoek-aanpassen/:reportId',
	CHANGE_MAINTENANCE_APPOINTMENT_SUCCESS: '/afspraak-reparatieverzoek-aanpassen/:reportId/succes',
	CHANGE_MAINTENANCE_APPOINTMENT_ERROR: '/afspraak-reparatieverzoek-aanpassen/:reportId/mislukt',

	MAIL_PREFERENCES: '/emailvoorkeuren-wijzigen',
	RENTAL_REFERENCE_LETTER: '/verhuurdersverklaring-aanvragen',
	RENTAL_REFERENCE_LETTER_START: '/verhuurdersverklaring-aanvragen/start',
	RENTAL_REFERENCE_LETTER_SUCCESS: '/verhuurdersverklaring-aanvragen/succes',
	RENTAL_REFERENCE_LETTER_ERROR: '/verhuurdersverklaring-aanvragen/mislukt',

	RENT_REDUCTION_REQUEST: '/huurverlaging-aanvragen',
	RENT_REDUCTION_REQUEST_SUCCESS: '/huurverlaging-aanvragen/succes',
	RENT_REDUCTION_REQUEST_ERROR: '/huurverlaging-aanvragen/mislukt',

	ADVANCEMENT_ADJUSTMENT: '/voorschot-aanpassen',
	ADVANCEMENT_ADJUSTMENT_SUCCESS: '/voorschot-aanpassen/succes',
	ADVANCEMENT_ADJUSTMENT_ERROR: '/voorschot-aanpassen/mislukt',

	REQUEST_NAMETAG: '/naamplaatje-aanvragen',
	REQUEST_NAMETAG_SUCCESS: '/naamplaatje-aanvragen/succes',
	REQUEST_NAMETAG_ERROR: '/naamplaatje-aanvragen/mislukt',

	RENT_INCREASE_OBJECTION: '/bezwaar-huurverhoging',
	RENT_INCREASE_OBJECTION_CONFIRMATION: '/bezwaar-huurverhoging/succes',
	RENT_INCREASE_OBJECTION_ERROR: '/bezwaar-huurverhoging/mislukt',

	MATCHMAIL_CONSENT: '/emailvoorkeuren-wijzigen/match-mail-consent',
	MATCHMAIL_CONSENT_CONFIRMATION: '/emailvoorkeuren-wijzigen/match-mail-consent/succes',
	MATCHMAIL_CONSENT_ERROR: '/emailvoorkeuren-wijzigen/match-mail-consent/mislukt',

	KLUSSEN_IN_JE_WONING: '/klussen-in-je-woning',
	KLUSSEN_IN_JE_WONING_CONTRACT: '/klussen-in-je-woning/contract',
	KLUSSEN_IN_JE_WONING_RESULT: '/klussen-in-je-woning/:categoryId/:changeId/succes',
	KLUSSEN_IN_JE_WONING_OUTCOME: '/klussen-in-je-woning/:categoryId/:changeId',
	KLUSSEN_IN_JE_WONING_OTHER: '/klussen-in-je-woning/iets-anders',
	KLUSSEN_IN_JE_WONING_OTHER_RESULT: '/klussen-in-je-woning/iets-anders/succes',

	HUURCONTRACT_KOPPELEN: '/huurcontract-koppelen',
	HUURCONTRACT_KOPPELEN_CONFIRMATION: '/huurcontract-koppelen/succes',

	CHANGE_EMAIL_MULTI_USER: '/emailadres-aanpassen',
	CHANGE_EMAIL_MULTI_USER_CONFIRMATION: '/emailadres-aanpassen/succes',

	REPAIR_STATUS: '/volg-mijn-reparatie',

	NO_ACCESS: '/geen-toegang',

	NOT_FOUND: '/not-found',

	DOCUMENT_VIEW: '/document-view',

	CREATE_ACCOUNT: '/account-aanmaken',

	WOONFRAUDE_MELDEN: '/woonfraude-melden',
	WOONFRAUDE_MELDEN_SUCCESS: '/woonfraude-melden/success',
	WOONFRAUDE_MELDEN_ERROR: '/woonfraude-melden/error',

} as const;

export const allRoutes = Object.values(Paths).map((path) => path);
