/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeEmailSingularErrorResponse } from '../models/ChangeEmailSingularErrorResponse';
import type { ChangeEmailSingularOkResponse } from '../models/ChangeEmailSingularOkResponse';
import type { ChangeEmailSingularRequest } from '../models/ChangeEmailSingularRequest';
import type { DaemonUpdateProfileRequest } from '../models/DaemonUpdateProfileRequest';
import type { GetProfileResponse } from '../models/GetProfileResponse';
import type { UpdateProfileRequest } from '../models/UpdateProfileRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static postAccountApiAccountChangeEmailSingular(
requestBody?: ChangeEmailSingularRequest,
): CancelablePromise<(ChangeEmailSingularOkResponse | ChangeEmailSingularErrorResponse)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/account/api/account/change-email-singular',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Accepted
     * @throws ApiError
     */
    public static patchAccountApiAccountProfile(
requestBody?: UpdateProfileRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account/api/account/profile',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param customerId 
     * @returns GetProfileResponse Success
     * @throws ApiError
     */
    public static getAccountApiAccountProfile(
customerId?: number,
): CancelablePromise<GetProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/api/account/profile',
            query: {
                'customerId': customerId,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * @param customerId 
     * @param accountIdentifier 
     * @returns GetProfileResponse Success
     * @throws ApiError
     */
    public static getAccountApiAccountDaemonProfile(
customerId?: number,
accountIdentifier?: string,
): CancelablePromise<GetProfileResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/account/api/account/daemon/profile',
            query: {
                'customerId': customerId,
                'accountIdentifier': accountIdentifier,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Accepted
     * @throws ApiError
     */
    public static patchAccountApiAccountDaemonProfile(
requestBody?: DaemonUpdateProfileRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/account/api/account/daemon/profile',
            body: requestBody,
            mediaType: 'application/json-patch+json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }

}
