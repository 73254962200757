import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Details = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M32 12H8C6.89543 12 6 12.8954 6 14V28C6 29.1046 6.89543 30 8 30H32C33.1046 30 34 29.1046 34 28V14C34 12.8954 33.1046 12 32 12Z'
				fill='#FFBFA5'
			/>
			<path
				d='M12.5 23.5C14.9853 23.5 17 21.4853 17 19C17 16.5147 14.9853 14.5 12.5 14.5C10.0147 14.5 8 16.5147 8 19C8 21.4853 10.0147 23.5 12.5 23.5Z'
				fill='#FF7842'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8.00001 28C7.99869 27.4087 8.11419 26.8229 8.33987 26.2764C8.56555 25.7298 8.89696 25.2332 9.31509 24.8151C9.73322 24.397 10.2298 24.0655 10.7764 23.8399C11.3229 23.6142 11.9087 23.4987 12.5 23.5C13.0913 23.4987 13.6771 23.6142 14.2236 23.8399C14.7702 24.0655 15.2668 24.397 15.6849 24.8151C16.1031 25.2332 16.4345 25.7298 16.6602 26.2764C16.8858 26.8229 17.0013 27.4087 17 28H8.00001Z'
				fill='#FF7842'
			/>
			<path
				d='M15.14 19.75C15.14 20.4502 14.8618 21.1217 14.3667 21.6168C13.8717 22.1119 13.2002 22.39 12.5 22.39C11.7998 22.39 11.1283 22.1119 10.6332 21.6168C10.1381 21.1217 9.85999 20.4502 9.85999 19.75'
				stroke='#00215B'
				strokeMiterlimit='10'
			/>
			<path
				d='M20 16H32'
				stroke='#FF7842'
				strokeMiterlimit='10'
			/>
			<path
				d='M20 19H32'
				stroke='#FF7842'
				strokeMiterlimit='10'
			/>
			<path
				d='M20 22H32'
				stroke='#FF7842'
				strokeMiterlimit='10'
			/>
			<path
				d='M32 26V30.5C31.9974 30.8987 31.8371 31.2803 31.5542 31.5613C31.2713 31.8423 30.8888 32 30.49 32H20'
				stroke='#00215B'
				strokeMiterlimit='10'
			/>
		</svg>
	);
};

export default Details;
