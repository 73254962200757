import dayjs from 'dayjs';
import 'dayjs/locale/nl';
import 'dayjs/locale/en';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const setupDayjs = () => {
	dayjs.extend(isSameOrBefore);
	dayjs.extend(isBetween);
	dayjs.extend(customParseFormat);
	dayjs.extend(isSameOrAfter);
	dayjs.extend(isToday);
	dayjs.extend(timezone);
	dayjs.extend(utc);
};

export default setupDayjs;
