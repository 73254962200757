import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const Invoices = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.32001 31.66L27.15 34.1L29.93 13.86L30.31 11.09L30.68 8.34L18.2 6.63L12.85 5.9L12.09 11.42L9.32001 31.66Z'
				fill='#0061CD'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11 33H29V12.57V9.77V7H16.4H11V12.57V33Z'
				fill='#85BDED'
			/>
			<path
				d='M20 10.5H13V11.5H20V10.5Z'
				fill='#00215B'
			/>
			<path
				d='M21 13.5H13V14.5H21V13.5Z'
				fill='#00215B'
			/>
			<path
				d='M20 16.5H13V17.5H20V16.5Z'
				fill='#00215B'
			/>
			<path
				d='M27 16.51H22.99V17.51H27V16.51Z'
				fill='#00215B'
			/>
			<path
				d='M25.49 15H24.49V19.01H25.49V15Z'
				fill='#00215B'
			/>
			<path
				d='M14.07 27.8H13V26.2H14.07C14.45 24.37 16.11 23 18.1 23C19.23 23 20.26 23.45 21.01 24.17L19.73 25.26C19.29 24.83 18.71 24.59 18.1 24.59C17.09 24.59 16.2 25.25 15.88 26.19H18.27V27.79H15.88C16.21 28.73 17.09 29.39 18.1 29.39C18.71 29.39 19.29 29.15 19.73 28.72L21.01 29.81C20.27 30.53 19.24 30.98 18.1 30.98C16.11 30.98 14.45 29.61 14.07 27.78V27.8Z'
				fill='#0061CD'
			/>
		</svg>
	);
};

export default Invoices;
