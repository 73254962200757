import { LANGUAGE_STORAGE_KEY } from '../../../../Shared.Frontend/constants/index';
import { Locale, setLocale, localLng } from 'constants/locale';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageSelectorContext } from '../../hooks/app/useLanguageSelector';
import 'dayjs/locale/nl';
import safeLocalStorage from 'utils/safeLocalstorage';
interface Props {
	children: ReactNode;
}

const LanguageProvider: FunctionComponent<Props> = ({ children }) => {
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState<string>(i18n.language);

	useEffect(() => {
		if (i18n.language !== language) {
			i18n.changeLanguage(language);
			if (language === Locale.NL) {
				setLocale('nl');
			} else {
				setLocale('en');
			}
		} else {
			localLng();
		}

		safeLocalStorage.setItem?.(LANGUAGE_STORAGE_KEY, language);
	}, [language, i18n]);

	return (
		<LanguageSelectorContext.Provider value={{ language, setLanguage }}>{children}</LanguageSelectorContext.Provider>
	);
};

export default LanguageProvider;
