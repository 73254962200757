import { useAccount } from '@azure/msal-react';

export const useUserIsContractor = () => {
	const account = useAccount();
	return Boolean(
		account?.idTokenClaims?.extension_Roles?.includes('contractor'),
	);
};

export const useUserIsCustomer = () => {
	const account = useAccount();
	return Boolean(
		account?.idTokenClaims?.extension_Roles?.includes('customer'),
	);
};
