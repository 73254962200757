import clsx from 'clsx';
import Check from 'assets/svg/check.svg?react';
import { FunctionComponent, ReactNode, useContext } from 'react';
import { ListContext } from './List';
import './ListItem.scss';
import Typography from 'components/typography/Typography';

interface DotProps {
	className?: string;
}

const Dot: FunctionComponent<DotProps> = ({ className }) => {
	return (
		<svg
			width='10'
			height='10'
			viewBox='0 0 10 10'
			version='1.1'
			className={className}
		>
			<circle
				cx='5'
				cy='5'
				r='2'
			/>
		</svg>
	);
};

interface Props {
	children: ReactNode;
	className?: string;
	listType?: 'bullet' | 'check';
	testid?: string;
}

const ListItem: FunctionComponent<Props> = ({ children, listType, className = '', testid }) => {
	const { childSpacing, listType: listTypeFromContext, lineHeight, listIconSize, iconColor, textColor, textSize } = useContext(
		ListContext,
	);

	const classes = clsx('list-item', className, {
		'list-item--extra-large-spacing': childSpacing === 'extra-large',
		'list-item--large-spacing': childSpacing === 'large',
		'list-item--small-spacing': childSpacing === 'small',
		'list-item--line-height-large': lineHeight === 'large',
		'list-item--line-height-small': lineHeight === 'small',
		[`list-item--text-color-${textColor}`]: textColor !== undefined,
		[`list-item--icon-size-${listIconSize}`]: listIconSize !== 'normal',
	});

	const iconClasses = clsx('list-item__icon', {
		[`list-item__icon--size-${listIconSize}`]: listIconSize !== 'normal',
		[`list-item__icon--color-${iconColor}`]: iconColor !== undefined,
	});

	const checkType =
		(listType !== undefined && listType === 'check') || (listType === undefined && listTypeFromContext === 'check');

	return (
		<li
			className={classes}
			data-testid={testid}
		>
			{!checkType && <Dot className={iconClasses} />}
			{checkType && <Check className={iconClasses} />}
			<Typography
				variant={textSize}
				color={textColor}
			>
				{children}
			</Typography>
		</li>
	);
};

export default ListItem;
