import { AriaAttributes } from 'react';

type Props = AriaAttributes & {
	className?: string;
}

const PaymentMethod = ({ className, ...restProps }: Props) => {
	return (
		<svg
			className={className}
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<path
				d='M29.26 9.27H6.74C5.77902 9.27 5 10.049 5 11.01V23.53C5 24.491 5.77902 25.27 6.74 25.27H29.26C30.221 25.27 31 24.491 31 23.53V11.01C31 10.049 30.221 9.27 29.26 9.27Z'
				fill='#B3E9B1'
			/>
			<path
				d='M31 11.98H5V14.54H31V11.98Z'
				fill='#60D15D'
			/>
			<path
				d='M16.67 17.6H6.67V18.6H16.67V17.6Z'
				fill='#60D15D'
			/>
			<path
				d='M27.91 32.81L27 34.92H24.14L23.31 32.95L22.16 32.28L20.02 33.13L18 31.11L18.8 29.13L18.46 27.84L16.35 26.93V24.07L18.32 23.24L18.99 22.09L18.14 19.95L20.16 17.93L22.14 18.73L23.43 18.39L24.34 16.28H27.2L28.03 18.25L29.18 18.92L31.32 18.07L33.34 20.09L32.54 22.07L32.88 23.36L34.99 24.27V27.13L33.02 27.96L32.35 29.11L33.2 31.25L31.18 33.27L29.2 32.47L27.91 32.81ZM25.67 28.46C27.26 28.46 28.54 27.17 28.54 25.59C28.54 24.01 27.25 22.72 25.67 22.72C24.09 22.72 22.8 24.01 22.8 25.59C22.8 27.17 24.09 28.46 25.67 28.46Z'
				fill='#60D15D'
			/>
			<path
				d='M12.56 20.71H6.67V21.71H12.56V20.71Z'
				fill='#60D15D'
			/>
			<path
				d='M25.38 29.23C25.27 29.23 25.17 29.23 25.06 29.22C24.06 29.14 23.16 28.67 22.51 27.9L23.28 27.26C23.75 27.82 24.41 28.16 25.15 28.23C26.66 28.35 27.99 27.24 28.12 25.73C28.22 24.51 27.49 23.35 26.34 22.92L26.69 21.98C28.26 22.56 29.25 24.14 29.11 25.81C28.94 27.76 27.3 29.24 25.38 29.24V29.23Z'
				fill='#00215B'
			/>
			<path
				d='M18.3 29.53L18.02 28.48L15.83 27.53V24.01L17.95 23.11L18.42 22.31L17.54 20.09L20.03 17.6L22.17 18.46L23.06 18.23L24.01 16.04H27.53L28.43 18.16L29.23 18.62L31.45 17.75L33.7 20L33 20.71L31.21 18.92L29.15 19.73L27.64 18.85L26.87 17.04H24.67L23.78 19.07L22.1 19.52L20.27 18.78L18.71 20.33L19.53 22.39L18.65 23.9L16.83 24.67V26.87L18.87 27.75L19.27 29.28L18.3 29.53Z'
				fill='#00215B'
			/>
		</svg>
	);
};

export default PaymentMethod;
