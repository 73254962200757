import { useState, useCallback, RefObject } from 'react';

type UseTransitionHeight = (
  ref?: RefObject<HTMLElement>
) => [number | undefined, (node: HTMLElement) => void, (node: HTMLElement) => void];

const useTransitionHeight: UseTransitionHeight = (ref) => {
	const [height, setHeight] = useState<number | undefined>(undefined);
	const onSetHeight = useCallback(
		(node: HTMLElement) => {
			const element = ref && ref.current ? ref.current : node;
			if (element) {
				if (element.clientHeight === 0) {
					setTimeout(() => {
						setHeight(element.clientHeight);
					}, 5);
				} else {
					setHeight(element.clientHeight);
				}
			}
		},
		[setHeight, ref],
	);
	const onResetHeight = useCallback(() => {
		setHeight(undefined);
	}, [setHeight]);

	return [height, onSetHeight, onResetHeight];
};

export default useTransitionHeight;
