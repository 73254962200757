import safeWindow from './safeWindow';

export type EnvVars = {
	REACT_APP_WEBSITE?: string,
	GATEWAY_BASE_URL?: string,
	OKAPI_BASE_URL?: string,
	ENVIRONMENT?: 'Test' | 'Acceptance' | 'Production',
	B2C_LOGIN?: string,
	B2C_CREATE_ACCOUNT?: string,
	B2C_FORGOT_PASSWORD?: string,
	B2C_CHANGE_PASSWORD?: string,
	B2C_CHANGE_EMAILADDRESS?: string,
	TERMS_URL?: string,
};

export const safeEnv = <K extends keyof EnvVars>(key: K): EnvVars[K] | undefined => {
	return (
		!safeWindow.env?.[key] || safeWindow.env?.[key]?.charAt(0) === '{'
			? import.meta.env[`VITE_${key}`]
			: safeWindow.env?.[key]
	);
};
