import { useMemo } from 'react';
import { createName } from 'utils/name';
import { useQuery, useMutation } from '@tanstack/react-query';
import { UserService } from '../../../../Shared.Frontend/generated-portal-woonstad/services/UserService';
import { UserProfile } from '../../../../Shared.Frontend/generated-portal-woonstad/models/UserProfile';

/**
 * Reduces empty string and null values to undefined
 */
const reduceUserProfile = (userProfile?: UserProfile) => {
	if (userProfile === undefined) {
		return undefined;
	}

	return Object.keys(userProfile).reduce(
		(acc, key) => {
			const typedKey = key as keyof typeof userProfile;

			if (userProfile[typedKey] === null || userProfile[typedKey] === '') {
				return { ...acc, [key]: undefined };
			}

			return { ...acc, [key]: userProfile[typedKey] };
		},
		{ id: '', email: '', address: '', gender: '' } as UserProfile,
	);
};

const selectUserPhoneNumber = (userProfile?: UserProfile) => {
	return userProfile?.customer?.phoneMobile || userProfile?.customer?.phonePrivate || undefined;
};

export const useUserProfile = () => {
	const { data, isLoading, isSuccess } = useQuery({
		queryKey: ['user-profile'],
		queryFn: () => UserService.userGetProfile(),
	});

	const profileName = useMemo(() => [data?.firstname, data?.initials, data?.lastname].filter((val) => val).join(' '), [data]);
	const userProfile = useMemo(() => reduceUserProfile(data), [data]);
	const name = useMemo(() => createName(userProfile?.customer), [userProfile?.customer]);
	const phoneNumber = useMemo(() => selectUserPhoneNumber(userProfile), [userProfile]);

	return {
		success: isSuccess,
		requesting: isLoading,
		customer: data?.customer,
		email: data?.email,
		profileName,
		name,
		phoneNumber,
		situation: data?.situation,
		income: data?.income,
		firstname: data?.firstname,
		initials: data?.initials,
		lastname: data?.lastname,
	};
};

export const useUpdateUserProfile = () => {
	const { data, mutate, isSuccess, isLoading, isError } = useMutation({ mutationFn: UserService.userUpdateProfile });
	return {
		data,
		mutate,
		isSuccess,
		isLoading: isLoading,
		isError,
	};
};
